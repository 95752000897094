
import { projects } from "@/api";
import { Actions, Mutations } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "FsnStatusToast",
  data() {
    return {
      snackbar: false,
      text: "Flex_240725_001 | 10/26/2024 10:00 am | 30% ",
      interval: 5000,
    };
  },
  computed: {
    fsnProcessingToast: function (): any {
      return this.$store.state.fsnProcessingToast;
    },
  },
  methods: {
    async getStatus(id: number) {
      const response = await projects.getFSNUploadProcessingStatus(id);
      const name = response.data.object_key.split("/").pop();
      const progress = response.data.upload_processing_status;
      const now = new Date();
      let ampm = "am";
      let h: any = now.getHours();
      let m: any = now.getMinutes();
      let s: any = now.getSeconds();
      if (h >= 12) {
        if (h > 12) h -= 12;
        ampm = "pm";
      }
      if (m < 10) m = "0" + m;
      if (s < 10) s = "0" + s;

      const date =
        now.toLocaleDateString() + " " + h + ":" + m + ":" + s + " " + ampm;

      this.text = `${name} | ${date} | ${progress}% `;

      if (Number(progress) < 100) {
        setTimeout(() => {
          this.getStatus(id)
        }, this.interval)
      } else {
        this.$store.dispatch(Actions.GetCompanyAccount)
        this.$store.dispatch(Actions.GetCompanyProjects)
        this.$store.commit(Mutations.SetFSNProcessingToast, 0);
      }
    },
    close() {
      this.snackbar = false;
    },
  },
  watch: {
    fsnProcessingToast: {
      immediate: true,
      deep: true,
      handler() {
        if (this.fsnProcessingToast !== 0) {
          this.getStatus(this.fsnProcessingToast);
          this.snackbar = true;
        }
      },
    },
  },
});
