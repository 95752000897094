import { IAssociate } from '..';
import { AssociateRoles } from '../associates/models';
import { IBaseResponse } from '../common'

export interface ICompanyGetResponse extends IBaseResponse {
  "data": ICompany[]
}
export interface ICompanyAccountGetResponse extends IBaseResponse {
  "data": ICompanyAccount
}
export interface INlcSignupRequest {
  "first_name": string,
  "last_name": string,
  "user_name": string,
  "password": string,
  "phone_number": string,
  "roles": AssociateRoles[],
  "company": {
    "name": string,
    "renewal_date": string,
    "license": {
      "license_name": string
    }
  }
}
export interface INlcVerifyUserEmailResponse extends IBaseResponse {
  "data": {
    "userExists": boolean
  }
}

export interface ICompany {
  "id": number,
  "name": string,
  "address1": string,
  "address2"?: string,
  "address3"?: string,
  "address4"?: string,
  "city": string,
  "state": string,
  "country": string,
  "zipcode": string,
  "max_share_groups": number,
  "created_by": string,
  "created_datetime": string,
  "updated_by"?: string,
  "updated_datetime"?: string
  renewal_date?: string
  account_number: number,
  status: number,
  "cao"?: IAssociate,
  "license"?: {
    file_expiry_num_days: number
    id: number
    license_name: string
    number_of_users_limit: number
    storage_limit: number
  },
  card_info?: {
    expiry?: string
  },
  gssi_company: boolean
  account?: ICompanyAccount
}

export interface ICompanyAccount {
  "cao": IAssociate,
  "license_info": {
    "total_storage_used": string,
    "total_storage_limit": string,
    "total_file_expiry_num_days": number,
    "renewal_date": string,
    "account_number": number
    "modules": ICompanyAccountModule[]
    percent_used: string,
    license_id: number,
    total_users_limit: number
  }
  card_info: ICardInfo
}
export interface ICardInfo {
  expiry?: string
  last4?: string
}


export interface ICheckoutPaymentRequest {
  "additional_users": number,
  "storage": number
}

export interface ICheckoutPaymentResponse extends IBaseResponse {
  "data": {
    "payment_url": string,
    "order_id": number,
    "amount": string,
    "additional_users": number,
    "storage": number,
    "customer": {
      "name": string,
      "email": string
    }
  }
}


export interface ICompanyAccountModule {
  "module_id": number,
  "user_licenses_used": number,
  "user_licenses_purchased": string,
  "module_name": string
}

export enum CompanyStatus {
  ACTIVE = 1,
  SUSPENDED = 2,
  EXPIRED = 3
}

export enum LicenseType {
  Pro = 1,
  Free = 2,
  FreeTrial = 3
}


