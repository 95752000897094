import { IBaseResponse, IProject } from "..";

export interface IDashboardGetResponse extends IBaseResponse {
  data: {
    "data": IDashboardData[],
    "label": string
  }
}
export interface IDashboardEquipmentGetResponse extends IBaseResponse {
  data: IDashboardEquipmentsData
}
export interface IDashboardEquipmentsData {
  "count_by_device_group": {
    "name": string,
    "count": number
  }[],
  total_device_count: number
}
export interface IDashboardData {
  "label": string,
  "count": number
}

export enum DashboardTimePeriod {
  Today = 'today',
  Yesterday = 'yesterday',
  This_Week = 'this_week',
  Last_Week = 'last_week',
  This_Month = 'this_month',
  Last_Month = 'last_month',
  This_Year = 'this_year',
  Last_Year = 'last_year'
}


// activities log
export interface IDashboardActivityLogGetResponse extends IBaseResponse {
  data: IDashboardActivityLog[]
}
export interface IDashboardActivityLog {
  "id": number,
  "from_data": string | null,
  "to_data": {
    "id": number,
    "created_by": string,
    "project_id": number,
    "created_datetime": string,
    "associate_group_id": number
  },
  "entity_id": number,
  "entity": string,
  "parent_entity_id": number,
  "parent_entity": string,
  "parent_entity_name": string,
  "action": "add",
  "updated_keys": string | null,
  "created_by": string,
  "invoked_api": string,
  "created_datetime": string,
  "updated_by": null,
  "updated_datetime": null
}

// group
export interface IDashboardGroupProjectsGetResponse extends IBaseResponse {
  "data": {
    "group_id": number,
    "size": string,
    "projects": IProject[]
  }
}
export interface IDashboardLocationsGetResponse extends IBaseResponse {
  data: IDashboardLocation[]
}
export interface IDashboardLocation {
  "project.address": string
  "project.id": number,
  "project.name": string,
  "project.location.id": number,
  "project.location.x_coordinate": string,
  "project.location.y_coordinate": string,
  "project.location.z_coordinate": string,
  "project.created_datetime": string
}