
import { IAssociate } from "@/api";
import { bufferToImage, getInitials } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "UserIcon",
  props: {
    color: String,
    text: String,
    size: String,
    bordered: String,
    fontSize: String,
    associate: Object as () => IAssociate,
  },
  computed: {
    _color: function (): string {
      return this.color || this.associate?.profile_bg_color || "primary";
    },
    _size: function (): string {
      return this.size || '80';
    },
    _bordered: function (): string {
      return this.bordered ? "bordered" : "";
    },
    initials: function (): string {
      if (this.text) {
        return this.text;
      }
      return getInitials(this.associate)
    },
    profilePic: function (): string {
      if (this.associate?.profile_pic?.data.length) {
        return bufferToImage(this.associate.profile_pic.data);
      }
      return "";
    },
  },
  methods: {},
});
