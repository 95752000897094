import axios from 'axios'
import { HeaderKeys, baseUrl, getDomain, transformResponse } from '../common'
import { ICheckoutPaymentRequest, ICheckoutPaymentResponse, ICompanyAccountGetResponse, ICompanyGetResponse, INlcSignupRequest, INlcVerifyUserEmailResponse } from './models'

const companiesUrl = () => baseUrl + '/companies'
const get = async (id: number): Promise<ICompanyGetResponse> => {
  return transformResponse(await axios.get(
    companiesUrl() + '/' + id
  ))
}

const getAll = async (): Promise<ICompanyGetResponse> => {
  return transformResponse(await axios.get(
    companiesUrl()
  ))
}

const updateStatus = async (companyId: number, status: string): Promise<void> => {
  return transformResponse(await axios.put(
    companiesUrl() + "/" + companyId + '/updateStatus', {
    status
  }
  ))
}
const updateGssiCompany = async (companyId: number, gssiCompany: number): Promise<void> => {
  return transformResponse(await axios.put(
    companiesUrl() + "/" + companyId + '/updateType', {
    gssi_company: gssiCompany
  }
  ))
}
const deleteCompany = async (companyId: number): Promise<void> => {
  return transformResponse(await axios.delete(
    companiesUrl() + "/" + companyId
  ))
}

const company = {
  manageAccount: async (companyId: number): Promise<ICompanyAccountGetResponse> => {
    return transformResponse(await axios.get(
      baseUrl + '/company/manageAccount?company_id=' + companyId
    ))
  },
  transferOwnershipRequest: async (companyId: number, new_owner_id: number): Promise<void> => {
    return transformResponse(await axios.post(
      baseUrl + `/company/transfer_owner_request?company_id=${companyId}`, {
      new_owner_id
    }
    ))
  },
  cancelTransferOwnershipRequest: async (companyId: number): Promise<void> => {
    return transformResponse(await axios.delete(
      baseUrl + `/company/transfer_owner_request?company_id=${companyId}`
    ))
  },
  transferOwnershipApproval: async (companyId: number, new_owner_id: number): Promise<void> => {
    return transformResponse(await axios.post(
      baseUrl + `/company/transfer_owner?company_id=${companyId}`, {
      new_owner_id
    }
    ))
  },
}

const nlc = {
  signup: async (request: INlcSignupRequest): Promise<any> => {
    return transformResponse(await axios.post(
      `${getDomain()}/nlc/api/v1/signup`,
      request,
    ))
  },
  verifyUserEmail: async (email: string): Promise<INlcVerifyUserEmailResponse> => {
    return transformResponse(await axios.get(
      `${getDomain()}/api/v1/verifyUserEmail?user_email=${email}`
    ))
  },
  verifyUserEmailReg: async (email: string): Promise<INlcVerifyUserEmailResponse> => {
    return transformResponse(await axios.get(
      `${getDomain()}/nlc/api/v1/verifyUserEmail?user_email=${email}`
    ))
  },
  setPassword: async (password: string): Promise<any> => {
    return transformResponse(await axios.put(
      `${getDomain()}/nlc/api/v1/associates/company_user`,
      { password }
    ))
  },
  sendResetPasswordEmail: async (email: string): Promise<any> => {
    return transformResponse(await axios.post(
      `${getDomain()}/nlc/api/v1/associates/reset_password`,
      { user_name: email }
    ))
  },

  // new APIs for sign up
  signupUser: async (email: string): Promise<any> => {
    return transformResponse(await axios.post(
      `${getDomain()}/nlc/api/v1/signup_user`,
      {
        email
      }
    ))
  },
  signupUserResendEmail: async (email: string): Promise<any> => {
    return transformResponse(await axios.post(
      `${getDomain()}/nlc/api/v1//signup_user/resend_email`,
      {
        email
      }
    ))
  },
  singupCompany: async (request: {
    "first_name": string,
    "last_name": string,
    "user_name": string,
    "password": string,
    "phone_number": string,
    "company": {
      "name": string
    }
  }): Promise<any> => {
    return transformResponse(await axios.post(
      `${getDomain()}/nlc/api/v1/signup_company`,
      request
    ))
  },

  // terms & conditions
  getTermsAndConditions: async (): Promise<{ data: { content: string } }> => {
    return transformResponse(await axios.get(
      `${getDomain()}/nlc/api/v1/user_agreements/terms`,
    ))
  },

  getUserAgreement: async (): Promise<{ data: { content: string } }> => {
    return transformResponse(await axios.get(
      `${getDomain()}/nlc/api/v1/user_agreements/signup`
    ))
  },

  updateUserAgreement: async (user_agreement: boolean): Promise<{ data: { content: string } }> => {
    return transformResponse(await axios.put(
      `${getDomain()}/nlc/api/v1/user_agreements/signup`, {
      user_agreement
    }
    ))
  },

  getPrivacyPolicy: async (): Promise<{ data: string }> => {
    return axios.get(
      `/GSSI-PrivacyPolicy-021424.html`,
    )
  },
}

const checkout = {
  payment: async (request: ICheckoutPaymentRequest): Promise<ICheckoutPaymentResponse> => {
    return transformResponse(await axios.post(
      baseUrl + '/checkout_payment',
      request,
    ))
  },
}

const license = {
  downgrade: async (company_id: number): Promise<any> => {
    return transformResponse(await axios.post(
      baseUrl + '/downgrade_license?company_id=' + company_id,
    ))
  },
  // deprecated - > use checkout module 
  upgrade: async (company_id: number, request: {
    "additional_users": number,
    "storage": number
  }): Promise<any> => {
    return transformResponse(await axios.post(
      baseUrl + '/upgrade_license?company_id=' + company_id,
      request
    ))
  },
  // deprecated - > use checkout module 
  freeTrial: async (company_id: number): Promise<any> => {
    return transformResponse(await axios.post(
      baseUrl + '/upgrade_license?company_id=' + company_id, {
      "additional_users": 15,
      "storage": 100
    }
    ))
  },
}

export const companies = {
  get,
  getAll,
  updateStatus,
  updateGssiCompany,
  deleteCompany,
  company,
  nlc,
  checkout,
  license
}
export * from './models'

