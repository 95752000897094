import { AssociateRoles, GssiRoles } from '@/api';
import store from '../store'

export const permissions = {
    computed: {
        hasAccountPermission: function (): boolean {
            return store.getters.hasPermissionTo(AssociateRoles.ManageAccount);
        },
        hasUsersPermission: function (): boolean {
            return store.getters.hasPermissionTo(AssociateRoles.ManageUsers);
        },
        hasGroupsPermission: function (): boolean {
            return store.getters.hasPermissionTo(AssociateRoles.ManageGroups);
        },
        hasEquipmentPermission: function (): boolean {
            return store.getters.hasPermissionTo(AssociateRoles.ManageEquipment);
        },
        hasProjectsPermission: function (): boolean {
            return store.getters.hasPermissionTo(AssociateRoles.ManageProjects);
        },
        hasReportsPermission: function (): boolean {
            return store.getters.hasPermissionTo(AssociateRoles.ManageReports);
        },
        hasGssiUsersPermission: function (): boolean {
            return store.getters.hasPermissionTo(GssiRoles.ManageUsers);
        },
        hasGssiProjectsPermission: function (): boolean {
            return store.getters.hasPermissionTo(GssiRoles.SharedProjects);
        },
        hasGssiEquipmentPermission: function (): boolean {
            return store.getters.hasPermissionTo(GssiRoles.ManageEquipment);
        },
    },
};