import { IBaseResponse } from "../common";


export interface IGetAccessTokenResponse {
  "data": {
    "id_token": string
    "access_token": string
    "refresh_token": string
    "expires_in": number
    "token_type": string
  }
}
export interface IAssociatesGetResponse extends IBaseResponse {
  "data": IAssociate[]
}

export interface IAssociatesUpdateResponse extends IBaseResponse {
  "data": IAssociate
}

export interface IAssociateAddRequest {
  "first_name": string,
  "last_name": string,
  "middle_name": string,
  "user_name": string,
  "roles": AssociateRoles[],
  "company": { "id": number },
  "associate_groups": number[]

}
export interface IAssociateInviteRequest {
  "user_name": string,
  "roles": AssociateRoles[],
  "company": { "id": number },
  "associate_groups": number[],
  profile_bg_color: string,
  profile_bg_intial?: string
}

export interface IAssociateUpdateRequest {
  "first_name"?: string,
  "last_name"?: string,
  "middle_name"?: string,
  "user_name"?: string,
  "roles"?: AssociateRoles[],
  "biography"?: string,
  associate_groups: number[]
}

export interface IAssociate {
  "id": number,
  "first_name": string,
  "last_name": string,
  "middle_name": string | null,
  "user_name": string,
  "user_password": string,
  "created_by": string,
  "created_datetime": string,
  "updated_by": string | null,
  "updated_datetime": string | null,
  "company": {
    "id": number,
    "name": string
    "license_id"?: number,
    "total_storage_limit"?: number,
    "total_file_expiry_num_days"?: number,
    "renewal_date"?: string
  },
  "associate_type": {
    "id": number,
    "name": string
  },
  status: AssociateStatus
  roles: AssociateRoles[] //| GssiRoles[]
  biography: string | null
  profile_pic?: {
    data: number[]
    type: string
  },
  associate_associates_groups: IAssociateAssociatesGroup[],
  profile_bg_color: string,
  profile_bg_intial: string
}

export interface IAssociateAssociatesGroup {
  associate: { id: number, user_name: string }
  associates_group: { id: number, name: string }
  company_id: number
  id: number
}

export enum AssociateStatus {
  InviteSent = 1,
  Active = 2,
  Deactivated = 3,
  ReinviteSent = 4
}
export enum AssociateRoles {
  ManageAccount = 'MANAGE_USERS',
  ManageUsers = "MANAGE_USERS",
  ManageGroups = 'MANAGE_GROUPS',
  ManageProjects = 'MANAGE_PROJECTS',
  ManageReports = 'MANAGE_REPORTS',
  ManageEquipment = 'MANAGE_EQUIPMENT',
  SharedProjects = "SHARED_PROJECTS"
}

export enum GssiRoles {
  ManageUsers = 'MANAGE_USERS',
  SharedProjects = 'SHARED_PROJECTS',
  ManageEquipment = 'MANAGE_EQUIPMENT'
}

/****************** GROUPS *****************/
export interface IGroup {
  "id": number,
  "name": string,
  "created_by": string,
  "created_datetime": string,
  "updated_by": string | null,
  "updated_datetime": string | null,
  "company": {
    "id": number,
    "name": string
  },
  "associate_associates_groups": IGroupAssociate[]
}
interface IGroupAssociate {
  "associate": {
    "id": number,
    "user_name": string
  },
  "associates_group": {
    "id": number,
    "name": string
  }
}

export interface IGroupGetAllResponse extends IBaseResponse {
  data: IGroup[]
}
export interface IGroupAddResponse extends IBaseResponse {
  data: IGroup
}
export interface IGroupAddRequest {
  "name": string,
  "company": { "id": number },
  "associates": number[]
}

export interface IGroupUsersResponse extends IBaseResponse {
  "data": {
    "group_id": string,
    "associates": IAssociate[]
  }
}

export enum AssociateType {
  CompanyAccountOwner = "COMPANY_ACCOUNT_OWNER",
  CompanyAdmin = "COMPANY_ADMIN",
  CompanyUser = "COMPANY_USER",
  GSSIAdmin = "GSSI_ADMIN"
}

export enum AssociateAction {
  CCExpiring = "cc_expiring_soon",
  CCExpired = "cc_expired",
  CCDeclined = "payment_failed",
}