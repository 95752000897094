import axios from 'axios'
import { INotesAddRequest, INotesAddResponse, INotesGetAllResponse, NotesEntity } from '..'
import { baseUrl, transformResponse } from '../common'

const notesUrl = () => baseUrl + '/notes'

const get = async (id: number): Promise<INotesGetAllResponse> => {
  return transformResponse(await axios.get(
    notesUrl() + '/' + id
  ))
}
const update = async (id: number, request: INotesAddRequest): Promise<INotesGetAllResponse> => {
  return transformResponse(await axios.put(
    notesUrl() + '/' + id,
    request
  ))
}
const deleteN = async (id: number): Promise<any> => {
  return transformResponse(await axios.delete(
    notesUrl() + '/' + id,
  ))
}

const project = {
  get: async (projectId: number): Promise<INotesGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: { 
          entity: NotesEntity.projects, 
          entity_id: projectId 
        },
        "sort_field":"order_id"
      }
    }
    return transformResponse(await axios.get(
      notesUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: INotesAddRequest): Promise<INotesAddResponse> => {
    return transformResponse(await axios.post(
      notesUrl(),
      {
        ...request,
        entity: NotesEntity.projects,
        entity_id : projectId
      }
    ))
  }
}

const areaOfInterest = {
  get: async (areaOfInterestId: number): Promise<INotesGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          entity: NotesEntity.projectsAreasOfInterests, 
          entity_id: areaOfInterestId
        },
        "sort_field":"order_id"
        
      }
    }
    return transformResponse(await axios.get(
      notesUrl(),
      {
        params
      }
    ))
  },
  add: async (areaOfInterestId: number, request: INotesAddRequest): Promise<INotesAddResponse> => {
    return transformResponse(await axios.post(
      notesUrl(),
      {
        ...request,
        entity: NotesEntity.projectsAreasOfInterests,
        entity_id : areaOfInterestId
      }
    ))
  }
}

export const notes = {
  get,
  update,
  delete: deleteN,
  project,
  areaOfInterest
}
export * from './models'