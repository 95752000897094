import { scans } from "@/api";
import { Module } from "vuex";
import { Actions } from "../models";

const scansModule: Module<any, any> = {
  namespaced: true,
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async download({ state, getters, dispatch, commit }, ids: number[]): Promise<boolean> {
      try {
        if (!ids.length) {
          throw 'Please select scans first.'
        }
        const response = await scans.downloadZip(ids);
        const disposition = response.headers['content-disposition']
        const fileName = disposition.split('filename=')[1]
        const blob = await new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        return true
      } catch (e) {
        dispatch(Actions.DisplayError, e, { root: true });
        return false
      }
    },

    async delete({ state, getters, dispatch, commit }, ids: number[]): Promise<boolean> {
      try {
        if (!ids.length) {
          throw 'Please select scans first.'
        }
        await scans.delete(ids)
        return true
      } catch (e) {
        dispatch(Actions.DisplayError, e, { root: true });
        return false
      }
    },

    async downloadFiles({ state, getters, dispatch, commit }, ids: number[]): Promise<boolean> {
      try {
        if (!ids.length) {
          throw 'Please select scans first.'
        }
        const response: any = await scans.details.download(ids)
        const disposition = response.headers['content-disposition']
        const fileName = disposition?.split('filename=')?.[1] || 'files.zip'
        const blob = await new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        return true
      } catch (e) {
        dispatch(Actions.DisplayError, e, { root: true });
        return false
      }
    },

    async deleteFile({ state, getters, dispatch, commit }, ids: number[]): Promise<boolean> {
      try {
        if (!ids.length) {
          throw 'Please select scans first.'
        }
        await scans.details.delete(ids)
        return true
      } catch (e) {
        dispatch(Actions.DisplayError, e, { root: true });
        return false
      }
    },
  }
};

export default scansModule;