import { IBaseResponse } from "..";

export interface IAttachmentsGetAllResponse extends IBaseResponse {
  data: IAttachment[]
}

export interface IAttachment {
  "id": number,
  "name": string,
  "size": number,
  "cloud_file_reference_id": string,
  "cloud_file_icon"?:{
    "data": number[],
    "type": string
  }
  "entity_id": number,
  "entity": AttachmentsEntities,
  "created_by": string,
  "created_datetime": string,
  "updated_by": string | null,
  "updated_datetime": string | null,
  "expiry_datetime": string | null,
  "file_type": {
    "id": number,
    "name": string
  }
  projects_areas_of_interest: {
    id: number,
    name: string
  }
  title: string
  description: string
  order_id: number
}

export enum AttachmentsEntities {
  Projects = 'projects',
  Scans = 'scans',
  ProjectReports = "project_reports",
}

export enum AttachmentsProjectFileTypes {
  SitePhotos = 'Site Photos',
  ProjectDocuments = "PROJECT DOCUMENTS"
}

export enum AttachmentsScanFileTypes {
  TwoDScan = '2D SCAN',
  ThreeDGrid = '3D GRID'
}
