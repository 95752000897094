import axios from 'axios'
import { IBaseResponse } from '..'
import { baseUrl, transformResponse } from '../common'
import { IActivityLogGetResponse, IStorageGetNumbers, IStorageGetResponse } from './models'

export const storage = {
  get: async (): Promise<IStorageGetResponse> => {
    return transformResponse(await axios.get(
      baseUrl + '/storage_information'
    ))
  },
  getNumbers: async (): Promise<IStorageGetNumbers> => {
    const response = transformResponse(await axios.get(
      baseUrl + '/storage_information'
    ))
    for (const info in response.data) {
      const str = response.data[info]
      response.data[info] = parseFloat(str)
    }
    return response
  }
}
export const activityLog = {
  get: async (): Promise<IActivityLogGetResponse> => {
    const params = {
      list_info: {
        // row_count: rowCount,
        // start_index: this.index,
        sort_field: "created_datetime",
        sort_order: "DESC",
        get_total_count: true,
      },
    }
    return transformResponse(await axios.get(
      baseUrl + '/activity_logs',
      {
        params
      }
    ))
  }
}

export interface ISupportRequest {
  "to": string,
  "subject": string,
  "description": string,
  "project_id"?: string
}
export const support = {
  add: async (request: ISupportRequest): Promise<IBaseResponse> => {
    return transformResponse(await axios.post(
      baseUrl + '/support',
      request
    ))
  }
}
export * from './models'
