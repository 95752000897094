import { IBaseResponse } from "..";

export interface INotesAddRequest {
  note: string
}
export interface INotesGetAllResponse extends IBaseResponse{
  data: INote[]
}
export interface INotesAddResponse extends IBaseResponse{
  data: INote
}

export interface INote {
  "id": number,
  "entity_id": number,
  "entity": string,
  "note": string,
  "created_by": string,
  "created_datetime": string,
  "updated_by": string | null,
  "updated_datetime": string | null
}

export enum NotesEntity {
  projects = 'projects',
  scans = 'scans',
  projectsAreasOfInterests = 'projects_areas_of_interests'
}