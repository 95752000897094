
import { AssociateRoles, GssiRoles } from "@/api";
import { Actions, Mutations } from "@/store/models";
import Vue from "vue";
import Logo from "../common/Logo.vue";

export default Vue.extend({
  components: { Logo },
  name: "NavDrawer",
  props: ["drawer"],
  data() {
    return {
      default: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/dashboard",
          hidden: false,
          disabled: false,
        },
        {
          title: "Projects",
          icon: "mdi-folder-open",
          path: "/projects",
          hidden: true,
          role: AssociateRoles.ManageProjects,
          disabled: false,
        },
        {
          title: "Reports",
          icon: "mdi-file-chart",
          path: "/reports",
          hidden: true,
          role: AssociateRoles.ManageReports,
          disabled: false,
        },
        {
          title: "Groups",
          icon: "mdi-account-group",
          path: "/groups",
          hidden: true,
          role: AssociateRoles.ManageGroups,
          disabled: false,
        },
        {
          title: "Equipment",
          icon: "mdi-cog",
          path: "/equipment",
          hidden: true,
          role: AssociateRoles.ManageEquipment,
          disabled: false,
        },
        // { title: "Manage Scans", icon: "mdi-cloud-upload", path: "/scans" },
        // {
        //   title: "Cloud Folder",
        //   icon: "mdi-folder-open",
        //   path: "/cloudfolder",
        // },

        // {
        //   title: "Account",
        //   icon: "mdi-account-cog",
        //   path: "/accountmanagement",
        //   hidden: true,
        //   role: AssociateRoles.ManageAccount,
        // },
      ],
      gssiAdmin: [
        {
          title: "Accounts",
          icon: "mdi-office-building",
          path: "/gssiadmin/accounts",
          hidden: false,
          disabled: false,
        },
        {
          title: "Users",
          icon: "mdi-account",
          path: "/gssiadmin/users",
          hidden: false,
          role: GssiRoles.ManageUsers,
          disabled: false,
        },
        {
          title: "Shared Projects",
          icon: "mdi-folder-open",
          path: "/gssiadmin/projects",
          hidden: false,
          role: GssiRoles.SharedProjects,
          disabled: false,
        },
        {
          title: "Equipment",
          icon: "mdi-cog",
          path: "/gssiadmin/equipment",
          hidden: false,
          role: GssiRoles.ManageEquipment,
          disabled: false,
        },
      ],
    };
  },
  computed: {
    getDrawer: {
      get() {
        return (this as any).drawer;
      },
      set(drawer) {
        return drawer;
      },
    },
    roles: function (): Set<AssociateRoles> {
      return this.$store.getters.associateRoles;
    },
    displaySignup: function (): boolean {
      return (
        process.env.VUE_APP_SIGN_UP_AVAILABLE_TO_USER ===
        this.$store.state.associate.user_name
      );
    },
    items: function (): any {
      return (this as any).isGssiAdmin ? this.gssiAdmin : this.default;
    },
  },
  created() {
    if ((this as any).isGssiAdmin) {
      return;
    }
    const disableEquipement =
      process.env.VUE_APP_DISABLE_EQUIPMENT_VIEW === "true";
    const disableGroup = process.env.VUE_APP_DISABLE_GROUP_VIEW === "true";
    this.items.forEach((item: any) => {
      if (disableEquipement && item.title == "Equipment") {
        item.disabled = true;
      }
      if (disableGroup && item.title == "Groups") {
        item.disabled = true;
      }
    });
  },
  methods: {
    onInput(isOpen: boolean) {
      this.$emit("toggleDrawer", isOpen);
    },
    async logout() {
      await this.$store.dispatch(Actions.Logout);
    },
    async goToRegistration() {
      this.$router.push("registration");
    },
    support() {
      this.$store.commit(Mutations.SetSupportDialog, true);
    },
  },
  watch: {
    roles: {
      immediate: true,
      deep: true,
      handler(roles: Set<AssociateRoles>) {
        this.items.forEach((item: any) => {
          if (item.role) {
            if (roles.has(item.role!)) {
              item.hidden = false;
            } else {
              item.hidden = true;
            }
          }
        });
      },
    },
  },
});
