import { IAssociate, LicenseType } from '@/api'

export * from './constants'
export * from './models'

const convertUtcDateToLocalDate = (utcDateString: string): string => {
  if (!utcDateString) return ''
  const utcDateWithoutMillis = utcDateString.slice(0, -5) + "Z";
  const utcDate = new Date(utcDateWithoutMillis);
  /*
    Commented this to set the offset to zero, As all the values returned from API are in UTC.
    Need to confirm with Jessi, for any usecases which requires offset.
  */
  // const offsetMinutes = utcDate.getTimezoneOffset();
  const offsetMinutes = 0;
  const localTime = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);
  return localTime.toISOString()
}

export const getDate = (datetime: string): string => {
  datetime = convertUtcDateToLocalDate(datetime)
  const date = datetime.split('T')[0]
  const ymd = date.split('-')
  return ymd[1] + '/' + ymd[2] + '/' + ymd[0]
}

export const getYear = (datetime: string): string => {
  datetime = convertUtcDateToLocalDate(datetime)
  const date = datetime.split('T')[0]
  const ymd = date.split('-')
  return ymd[0]
}

export const getTime = (datetime: string): string => {
  datetime = convertUtcDateToLocalDate(datetime)
  let time: any = datetime.split('T')[1].split('.')[0] //hh:mm:ss

  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    // time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[4] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

export const getDatetime = (datetime: string): string => {
  return getDate(datetime) + " • " + getTime(datetime)
}

export const sizeInMBNumber = (size: number): number => {
  const temp = (size / 1000000).toFixed(2);
  return Number(temp)
}
export const sizeInMB = (size: number): string => {
  return (size / 1000000).toFixed(2) + " MB";
}
export const bufferToImage = (data: number[]): string => {
  try {
    return "data:image/jpeg;base64," + btoa(
      data.reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    )
  } catch (e) {
    // console.log(e)
    return ""
  }
}

export const byteToGB = (bytes: number): number => {
  return (bytes / (1000 * 1000 * 1000));
}
export const mbToGB = (mb: number): number => {
  return (mb / (1000));
}

export const pieGraphColors = ['#ec734b', '#FFC25A', '#41A4F2', '#2E5A7D', '#15AD8A']

export const toPascalCase = (str: string): string => {
  return (' ' + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => {
    return chr.toUpperCase()
  });
}
export const capitalize = (str: string): string => {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}
export const capitalizeBy_ = (str: string): string => {
  if (!str) {
    return str;
  }
  str = str.replaceAll("_", " ");
  return str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}


export const deepCopy = (item: any): any => {
  return JSON.parse(JSON.stringify(item))
}

// user_count is additional seats
// storage is in GB

export function getPlanCost(user_count: number, storage: number, isProLicense: boolean): IPlanCost {
  //All charges are in USD
  //Charges Document : https://docs.google.com/document/d/1QnuhvrCAet2p4q2Emqcv5gPtyCufbmmTtJ2Meyogc98/edit 

  const FIRST_TIER_PRICE = 29.99;//Till 10 Users Charge. : 29.99
  const SECOND_TIER_PRICE = 24.99;//Above 10 User Charge. : 24.99
  //User Limit
  const FIRST_TIER_LIMIT = 9;
  const costObj = { seatsCost: 0, storageCost: 0, total: (isProLicense ? 0 : PROTIER_BASE_PRICE) }; //exclude pro plan base cost if already pro

  //User Count Calculation
  if (user_count < FIRST_TIER_LIMIT) {
    costObj.seatsCost += user_count * FIRST_TIER_PRICE;
  } else {
    costObj.seatsCost += FIRST_TIER_LIMIT * FIRST_TIER_PRICE;
    costObj.seatsCost += (user_count - FIRST_TIER_LIMIT) * SECOND_TIER_PRICE;
  }

  const STORAGE_PRICES = {
    10: 10,
    100: 25,
    1000: 100
  } as any;

  switch (storage) {
    case 10:
    case 100:
    case 1000:
      costObj.storageCost += STORAGE_PRICES[storage];
      break;
    default:
      break;
  }

  return {
    total: (costObj.total + costObj.seatsCost + costObj.storageCost).toFixed(2),
    seatsCost: costObj.seatsCost.toFixed(2),
    storageCost: costObj.storageCost.toFixed(2),
  } as any
}


export const getInitials = (user: IAssociate) => {
  if (user?.first_name && user?.last_name) {
    return user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase()
  }
  return ''
}

export const isValidPassword = (str: string): boolean => {
  if (str.length < 8) {
    return false
  }
  // Regex to check if a string
  // contains uppercase, lowercase
  // special character & numeric value
  const pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );

  if (pattern.test(str)) {
    return true
  } else {
    return false
  }
}

export const isValidPhoneNumber = (val: string): boolean => {
  //eslint-disable-next-line
  let regex = new RegExp(/^\+[0-9]{1,3}[0-9]{10}/);
  if (!regex.test(val)) {
    return false
  }
  return true
}
export const isValidEmail = (val: string): boolean => {
  //eslint-disable-next-line
  let regex = new RegExp(/^\S[^@]+@{1}\S[^@]+.{1}\S[^@.]+$/);
  if (!regex.test(val)) {
    return false
  }
  return true
}

// expiry: "11/2024"
export const isExpiredCard = (val: string): boolean => {
  if (!val) {
    return false
  }
  const tokens = val.split('/')
  const month = Number(tokens[0])
  const year = Number(tokens[1])

  const today = new Date();
  const someday = new Date();
  someday.setFullYear(year, month, 1);

  if (someday < today) {
    return true
  }
  return false
}

export const getStorageInNumber = (val: string): number => {
  if (!val) {
    return 0
  }
  const tokens = val.split(' ')
  return Number(tokens?.[0])
}
export const STORAGE_OPTIONS = [1, 10, 100, 1000];
export const STORAGE_OPTION_LABELS = ["1 GB", "10 GB", "100 GB", "1 TB"];
export const PROTIER_BASE_PRICE = 49.99;
export interface IPlanCost {
  licenseType: LicenseType,
  tierCost: string,
  seats: number,
  seatsCost: string
  storage: number,
  storageCost: string
  total: string
}
export interface IPlanCosts {
  current: IPlanCost
  upgrading: IPlanCost
  additionalMonthlyTotal: string
}
const getStorageLabel = (gb: number): string => {
  const index = STORAGE_OPTIONS.indexOf(gb)
  let text = ""
  if (gb < 1) {
    text = "50 MB"
  } else {
    text = STORAGE_OPTION_LABELS[index]
  }
  return text + " of Storage";
}
const isNoCost = (cost: string): boolean => {
  return Number(cost) === 0
}
const getCost = (cost: string): string => {
  return isNoCost(cost) ? 'Included' : `$${cost}`
}

export const getPlanSummary = (planCosts: IPlanCosts): any[] => {
  const { upgrading } = planCosts
  const result = [] as any
  if (!isNoCost(upgrading.storageCost)) {
    result.push({
      item: getStorageLabel(upgrading.storage),
      cost: getCost(upgrading.storageCost),
    })
  }

  if (!isNoCost(upgrading.seatsCost)) {
    result.push({
      item: `${upgrading.seats} Seats`,
      cost: getCost(upgrading.seatsCost),
    })
  }
  return result
}
export const getCheckoutSummary = (planCosts: IPlanCosts): any[] => {
  const { upgrading } = planCosts
  return [
    {
      item: upgrading.licenseType === LicenseType.Pro ? "Pro tier" : "Free tier",
      cost: `$${upgrading.tierCost}`,
    },
    {
      item: getStorageLabel(upgrading.storage),
      cost: getCost(upgrading.storageCost),
    },
    {
      item: `${upgrading.seats} Seats`,
      cost: getCost(upgrading.seatsCost),
    },
    {
      item: `Total`,
      cost: `$${upgrading.total} / month`,
    },
  ]
}
export const BASE_FREE_SUMMARY = [
  {
    item: "Free tier",
    cost: `$0`,
  },
  {
    item: `50 MB of Storage`,
    cost: "Included",
  },
  {
    item: `1 Seat`,
    cost: `Included`,
  }
];
export const BASE_PRO_SUMMARY = [
  {
    item: "Pro tier",
    cost: `$${PROTIER_BASE_PRICE}`,
  },
  {
    item: `${STORAGE_OPTION_LABELS[0]} of Storage`,
    cost: "Included",
  },
  {
    item: `1 Seat`,
    cost: `Included`,
  },
];

