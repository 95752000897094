import { IBaseResponse } from "../common"

export interface IPaymentMethod {
  "id": number,
  "company_id": number,
  "payment_method_id": string,
  "payment_method_type": string,
  "billing_details": string,
  "card": string,
  "created": number,
  "stripe_customer_id": string,
  "created_by": string,
  "created_datetime": string
  "updated_by"?: null,
  "updated_datetime"?: null
}

// add payment method
export interface IPaymentMethodAddRequest {
  "card": {
    "token": string,
    "number"?: string,
    "exp_month"?: number,
    "exp_year"?: number,
    "cvc"?: string
  },
  "billing_details": IBillingDetails
}

export interface IPaymentMethodTokenAddRequest {
  "card": {
    "token": string
  },
  "billing_details": IBillingDetails
}

export interface IPaymentMethodAddResponse extends IBaseResponse {
  data: {
    "status"?: string,
    "data"?: IPaymentMethod,
    "error"?: {
      "type": string,
      "message": string,
      "code": string,
      "statusCode": number
    }
  }
}

// get payment methods
export interface IPaymentMethodGetResponse extends IBaseResponse {
  data: IPaymentMethod[]
}

// make payment
export interface IMakePaymentRequest {
  "additional_users"?: number,
  "total_users": number,
  "storage": number
}
export interface IMakePaymentResponse extends IBaseResponse {
  "data": {
    "paymentIntent": {
      "id": string,
      "object": string,
      "amount": number,
      "amount_capturable": number,
      "amount_details": {
        "tip": any
      },
      "amount_received": number,
      "application"?: null,
      "application_fee_amount"?: null,
      "automatic_payment_methods"?: null,
      "canceled_at"?: null,
      "cancellation_reason"?: null,
      "capture_method": string,
      "client_secret": string,
      "confirmation_method": string,
      "created": number
      "currency": string
      "customer": string
      "description"?: null,
      "invoice"?: null,
      "last_payment_error"?: null,
      "latest_charge": string
      "livemode": boolean,
      "metadata": {
        "id": string,
        "additional_users": string,
        "additional_users_cost": string,
        "company_id": string,
        "created_by": string,
        "invoice_amount": string,
        "invoice_type": string,
        "monthly_amount": string,
        "pro_rated": string,
        "storage": string,
        "storage_cost": string,
        "tier_cost": string
      },
      "next_action"?: null,
      "on_behalf_of"?: null,
      "payment_method"?: string
      "payment_method_options": {
        "card": {
          "installments"?: null,
          "mandate_options"?: null,
          "network"?: null,
          "request_three_d_secure": string
        }
      },
      "payment_method_types": [
        string
      ],
      "processing": null,
      "receipt_email": null,
      "review": null,
      "setup_future_usage": null,
      "shipping": null,
      "source": null,
      "statement_descriptor": null,
      "statement_descriptor_suffix": null,
      "status": "succeeded",
      "transfer_data": null,
      "transfer_group": null
    },
    "company_obj": {
      "status": string,
      "data": {
        "id": number,
        "name": string,
        "address1": string,
        "address2": null,
        "address3": null,
        "address4": null,
        "city": string,
        "state": string
        "country": string
        "zipcode": string
        "account_number": number,
        "renewal_date": string
        "additional_users_limit": number
        "additional_storage_limit": number
        "additional_file_expiry_num_days": number
        "total_users_limit": number
        "total_storage_limit": number
        "total_file_expiry_num_days": number
        "size": number
        "max_share_groups": number
        "status": number
        "pro_free": boolean
        "max_concurrent_sessions": number
        "created_by": string
        "created_datetime": string
        "updated_by": string
        "updated_datetime": string
        "license": {
          "id": number,
          "license_name": string,
          "storage_limit": number,
          "file_expiry_num_days": number,
          "number_of_users_limit": number
        },
        "associates": [
          {
            "id": number,
            "first_name": string
            "last_name": string
            "user_name": string
          }
        ]
      }
    }
    "paymentOrder": {
      "status": string,
      "data": {
        "id": number,
        "company_id": number,
        "order_id": string,
        "order_status": string,
        "payment_intent": string,
        "payment_status": string,
        "additional_users": number,
        "storage": number,
        "third_party": string,
        "created_datetime": string,
        "created_by": string
        "payment_method_info": string
      }
    }
  }
}

export interface IPaymentOrdersResponse extends IBaseResponse {
  data: IPaymentOrder[]
}
export interface IPaymentOrder {
  "id": number,
  "invoice_id": number,
  "order_id": string,
  "order_type": string,
  "company_id": number,
  "additional_users": number,
  "storage": number,
  "third_party_response": null,
  "third_party": string,
  "order_status": string,
  "order_amount": number,
  "payment_intent": string,
  "payment_status": string,
  "payment_method_info": string,
  "created_datetime": string,
  "updated_by": null,
  "updated_datetime": null
}

export interface ICheckoutCostResponse extends IBaseResponse {
  data: {
    "payment_summary": {
      "storage": number,
      "additional_users": number,
      "renewal_cost": string,
      "additional_users_cost": number,
      "storage_cost": string,
      "monthly_cost": string,
      "final_cost": string,
      "prorated": boolean,
      "order_type": string //"additional_license"
      "current_plan": {
        "additional_users_cost": string,
        "tier_cost": string,
        "monthly_cost": string,
        "additional_users": 12,
        "total_users": 13,
        "storage": number,
        "storage_cost": string
      },
      "upgrading": {
        "additional_users_cost": string,
        "tier_cost": string,
        "monthly_cost": string,
        "additional_users": number,
        "total_users": number,
        "storage": number,
        "storage_cost": string
      },
      "current_users_cost": string,
      "current_storage_cost": string,
      "total_users": number,
      "additional_monthly_cost": string,
      "current_and_additional_users_cost": string,
      "current_and_additional_storage_cost": string,
      "tier_cost": string,
    }
  }
}

export interface IOutstandingBalanceResponse extends IBaseResponse {
  data: {
    total_due: number
    unpaid_invoices: IInvoice[]
  }
}

export interface IPayAllInvoiceResponse extends IBaseResponse {
  data: {
    invoice_id: number
    payment_status: PaymentStatus
  }[]
}

export interface IBillingDetails {
  "name": string,
  "address": {
    "country": string,
    "city": string,
    "postal_code": string
  }
}

export interface IInvoice {
  "id": number,
  "company_id": number,
  "tier_cost": string,
  "additional_users": number,
  "additional_users_cost": string,
  "storage": number,
  "storage_cost": string,
  "invoice_type": string,
  "pro_rated": boolean,
  "invoice_amount": string,
  "monthly_amount": string,
  "payment_status": string,
  "payment_datetime": string,
  "created_by": string,
  "created_datetime": string,
  "updated_by": string,
  "updated_datetime": string
}

export enum PaymentStatus {
  Failed = "failed",
  Paid = "succeeded"
}