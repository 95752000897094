
import { Mutations } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "Toast",
  data() {
    return {
      snackbar: false,
      timeout: 5000,
    };
  },
  computed: {
    toast: function (): any {
      return this.$store.state.toast;
    },
  },
  methods: {},
  watch: {
    toast: {
      deep: true,
      handler() {
        this.snackbar = true;
        // setTimeout(() => {
        //   this.snackbar = false;
        // }, this.timeout);
      },
    },
  },
});
