import axios from 'axios'
import { IScansUpdateReqest } from '..'
import { baseUrl, transformResponse } from '../common'
import {
  IScansAddReqest, IScansAddResponse, IScansGetAllResponse, IScansFileTypesGetResponse,
  IScansGetResponse, IScansParams, IScansAnnotationsGetAllResponse, IScansAnnotationsAddResponse
} from './models'

const scansUrl = () => baseUrl + '/scans'
const get = async (id: number): Promise<IScansGetResponse> => {
  return transformResponse(await axios.get(
    scansUrl() + '/' + id
  ))
}

const getAll = async (params: IScansParams): Promise<IScansGetAllResponse> => {
  return transformResponse(await axios.get(
    scansUrl(),
    {
      params
    }
  ))
}

const getByProject = async (projectId: number): Promise<IScansGetAllResponse> => {
  const params = {
    list_info: {
      get_total_count: true,
      search_fields: {
        project_id: projectId
      }
    }
  };
  return transformResponse(await axios.get(
    scansUrl(),
    {
      params
    }
  ))
}

const getByDevice = async (deviceId: string): Promise<IScansGetAllResponse> => {
  const params = {
    list_info: {
      sort_field: "created_datetime",
      sort_order: "DESC",
      get_total_count: true,
      search_fields: {
        device_id: deviceId
      }
    }
  };
  return transformResponse(await axios.get(
    scansUrl(),
    {
      params
    }
  ))
}

const getByTimePeriod = async (timePeriod: string): Promise<IScansGetAllResponse> => {
  const params = {
    list_info: {
      time_period: timePeriod
    }
  };
  return transformResponse(await axios.get(
    scansUrl(),
    {
      params
    }
  ))
}

const getMyScans = async (): Promise<IScansGetAllResponse> => {
  const params = {
    list_info: {
      filter: 'my_list',
      get_total_count: true
    }
  };
  return transformResponse(await axios.get(
    scansUrl(),
    {
      params
    }
  ))
}

const getAllLatestScans = async (): Promise<IScansGetAllResponse> => {
  const params = {
    list_info: {
      sort_field: 'created_datetime',
      sort_order: 'DESC',
      get_total_count: true
    }
  };
  return transformResponse(await axios.get(
    scansUrl(),
    {
      params
    }
  ))
}

const add = async (scan: IScansAddReqest): Promise<IScansAddResponse> => {
  return transformResponse(await axios.post(
    scansUrl(),
    scan
  ))
}

const update = async (id: number, description: string): Promise<IScansAddResponse> => {
  return transformResponse(await axios.put(
    scansUrl() + '/' + id,
    {
      scan_description: description
    }
  ))
}
const move = async (id: number, request: IScansUpdateReqest): Promise<IScansAddResponse> => {
  return transformResponse(await axios.put(
    scansUrl() + '/' + id,
    request
  ))
}

const deleleScan = async (ids: number[]): Promise<IScansGetResponse> => {
  const params = {
    ids: ids.toString()
  }
  return transformResponse(await axios.delete(
    scansUrl(),
    {
      params
    }
  ))
}

const downloadZip = async (ids: number[]): Promise<any> => {
  const params = {
    ids: ids.toString()
  }
  return axios.get(
    scansUrl() + '/download_zip',
    {
      responseType: 'arraybuffer',
      params: params
    }
  )
}

/********** SCAN DETAILS / FILE *********/
const scanDetailsUrl = () => baseUrl + '/scan_details'
const details = {
  add: async (id: number, file: File, fileType: string): Promise<IScansAddResponse> => {
    const formData = new FormData();
    formData.append('scan_id', id.toString())
    formData.append('F22', file);
    formData.append('file_type', fileType)
    return transformResponse(await axios.post(
      scanDetailsUrl(),
      formData,
      // {
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   }
      // }
    ))
  },
  delete: async (ids: number[]): Promise<any> => {
    const params = {
      ids: ids.toString()
    }
    return transformResponse(await axios.delete(
      scanDetailsUrl(),
      {
        params
      }
    ))
  },
  download: async (ids: number[]): Promise<any> => {
    const params = {
      ids: ids.toString()
    }
    return axios.get(
      `${scanDetailsUrl()}/download_zip`,
      {
        params,
        responseType: 'arraybuffer',
      }
    )
  }
}

/********** ANNOTATION *********/
const annotationsUrl = () => baseUrl + '/annotations'
const annotations = {
  get: async (id: number): Promise<any> => {
    return transformResponse(await axios.get(
      annotationsUrl() + '/' + id
    ))
  },
  getByScan: async (scanId: number): Promise<IScansAnnotationsGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          scan_id: scanId
        }
      }
    }
    return transformResponse(await axios.get(
      annotationsUrl(),
      {
        params
      }
    ))
  },
  add: async (scanId: number, annotation: string): Promise<IScansAnnotationsAddResponse> => {
    return transformResponse(await axios.post(
      annotationsUrl(),
      {
        annotation_details: annotation,
        scan: { id: scanId }
      }
    ))
  },
  delete: async (id: number): Promise<any> => {
    return transformResponse(await axios.delete(
      annotationsUrl() + '/' + id
    ))
  },
}


/********** FILE TYPES *********/
const getFileTypes = async (): Promise<IScansFileTypesGetResponse> => {
  return transformResponse(await axios.get(
    baseUrl + '/scan_file_types'
  ))
}

// exports
export const scans = {
  get,
  getAll,
  getByProject,
  getByDevice,
  getByTimePeriod,
  getMyScans,
  getAllLatestScans,
  add,
  update,
  move,

  delete: deleleScan,
  downloadZip,
  details,
  annotations,
  fileType: {
    get: getFileTypes
  }
}
export * from './models'
