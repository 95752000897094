import axios from 'axios'
import { IProjectAreaOfInterestsGetAllResponse, IProjectAreaOfInterestsAddRequest, IProjectAreaOfInterestsAddResponse, IProjectAreaOfInterestsUpdateRequest, IBaseResponse, IProjectReportsGetAllResponse, IProjectReportsAddReponse, IProjectReportsAddRequest, IProjectReportsUpdateRequest, IReportHeaderTemplatesAddRequest, IReportHeaderTemplatesGetResponse, IReportHeaderTemplatesGetAllResponse } from '..'
import { baseUrl, transformResponse } from '../common'
import { IFSNUploadProcessingStatusGetResponse, IProjectAddRequest, IProjectAddResponse, IProjectsGetAllResponse, IprojectUpdateRequest } from './models'

const projectsUrl = () => baseUrl + '/projects'
const get = async (id: number): Promise<IProjectsGetAllResponse> => {
  return transformResponse(await axios.get(
    projectsUrl() + '/' + id
  ))
}

const getByCompany = async (companyId: number, shared = false): Promise<IProjectsGetAllResponse> => {
  const params = {
    list_info: {
      search_fields: {
        company_id: companyId
      }
    }
  } as any
  if (shared) {
    params.list_info.search_fields.status = 0
  }
  return transformResponse(await axios.get(
    projectsUrl(),
    {
      params
    }
  ))
}

const getAllLatest = async (companyId?: number): Promise<IProjectsGetAllResponse> => {
  const params = {
    list_info: {
      "sort_field": "updated_datetime", "sort_order": "DESC", "get_total_count": true, search_fields: {
        company_id: companyId
      }
    }
  }
  return transformResponse(await axios.get(
    projectsUrl(),
    {
      params
    }
  ))
}

const deleteP = async (id: number): Promise<IBaseResponse> => {
  return transformResponse(await axios.delete(
    projectsUrl() + '/' + id,
  ))
}

const add = async (request: IProjectAddRequest): Promise<IProjectAddResponse> => {
  return transformResponse(await axios.post(
    projectsUrl(),
    request
  ))
}
const update = async (id: number, request: IprojectUpdateRequest): Promise<IProjectAddResponse> => {
  return transformResponse(await axios.put(
    projectsUrl() + '/' + id,
    request
  ))
}

const download = async (ids: number[]): Promise<any> => {
  const params = {
    ids: ids.toString()
  }
  const response:any =  await axios.get(
    `${projectsUrl()}/download_zip`,
    {
      params,
      responseType: 'arraybuffer',
    }
  )
  const disposition = response.headers['content-disposition']
  const fileName = disposition?.split('filename=')?.[1] || 'file'
  const type = response.headers['content-type']
  const blob = await new Blob([response.data], { type });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const generateDownloadZip = async (ids: number[]): Promise<any> => {
  const params = {
    ids: ids.toString()
  }
  const response:any =  await axios.get(
    `${projectsUrl()}/generate_download_zip`,
    {
      params,
    }
  )
  const link = document.createElement("a");
  link.href = response.data.data.presignedUrl;
  link.target = "_blank"
  link.click();
}

const getFSNUploadProcessingStatus = async (id: number): Promise<IFSNUploadProcessingStatusGetResponse> => {
  return transformResponse(await axios.get(
    `${projectsUrl()}/uploads_queue/${id}`,
  ))
} 

/* Area of Interests */
const areaOfInterestPath = '/projects_areas_of_interests'
const areaOfInterests = {
  getAll: async (projectId: number): Promise<IProjectAreaOfInterestsGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          project_id: projectId
        }
      }
    }
    return transformResponse(await axios.get(
      baseUrl + areaOfInterestPath,
      {
        params
      }
    ))
  },
  add: async (request: IProjectAreaOfInterestsAddRequest): Promise<IProjectAreaOfInterestsAddResponse> => {
    return transformResponse(await axios.post(
      baseUrl + areaOfInterestPath,
      request
    ))
  },
  update: async (id: number, request: IProjectAreaOfInterestsUpdateRequest): Promise<IProjectAreaOfInterestsAddResponse> => {
    return transformResponse(await axios.put(
      baseUrl + areaOfInterestPath + '/' + id,
      request
    ))
  },
  delete: async (id: number): Promise<IBaseResponse> => {
    return transformResponse(await axios.delete(
      baseUrl + areaOfInterestPath + '/' + id,
    ))
  }
}

/* reports */
const reportsUrl = () => baseUrl + '/project_reports'
const reports = {
  getByCompany: async (companyId: number): Promise<IProjectReportsGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          company_id: companyId
        }
      }
    }
    return transformResponse(await axios.get(
      reportsUrl(),
      {
        params
      }
    ))
  },
  getByProject: async (projectId: number): Promise<IProjectReportsGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          project_id: projectId
        }
      }
    }
    return transformResponse(await axios.get(
      reportsUrl(),
      {
        params
      }
    ))
  },
  get: async (id: number): Promise<IProjectReportsGetAllResponse> => {
    return transformResponse(await axios.get(
      reportsUrl() + (id ? `/${id}` : '')
    ))
  },
  add: async (request: IProjectReportsAddRequest): Promise<IProjectReportsAddReponse> => {
    return transformResponse(await axios.post(
      reportsUrl(),
      request
    ))
  },
  update: async (id: number, request: IProjectReportsUpdateRequest): Promise<IProjectReportsAddReponse> => {
    return transformResponse(await axios.put(
      reportsUrl() + '/' + id,
      request
    ))
  },
  delete: async (id: number): Promise<IBaseResponse> => {
    return transformResponse(await axios.delete(
      reportsUrl() + '/' + id,
    ))
  },
  share: async (id: number, password: string): Promise<IProjectReportsAddReponse> => {
    return transformResponse(await axios.put(
      reportsUrl() + '/' + id + '/share',
      { password }
    ))
  },
  unshare: async (id: number): Promise<IProjectReportsAddReponse> => {
    return transformResponse(await axios.delete(
      reportsUrl() + '/' + id + '/unshare',
    ))
  },
  savePDFToProject: async (id: number, url?: string): Promise<any> => {
    return transformResponse(await axios.post(
      reportsUrl() + '/' + id + '/save_pdf_to_project',
      {
        client_url: window.location.origin
      }
    ))
  },
  downloadPDF: async (id: number, url?: string): Promise<any> => {
    const response: any = await axios.get(
      reportsUrl() + '/' + id + '/download?client_url='+window.location.origin,
      { responseType: 'blob' }
    )
    const disposition = response.headers['content-disposition']
    const fileName = disposition?.split('filename=')?.[1] || 'file'
    const type = response.headers['content-type']
    const blob = await new Blob([response.data], { type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },
}

/* report header templates */
const reportHeaderTemplatesUrl = () => baseUrl + '/report_header_templates'
const reportHeaderTemplates = {
  add: async (request: IReportHeaderTemplatesAddRequest): Promise<IReportHeaderTemplatesGetResponse> => {
    const formData = new FormData();
    for(const [key, val] of Object.entries(request)) {
      if (val)
      formData.append(key, val)
    }
    return transformResponse(await axios.post(
      reportHeaderTemplatesUrl(),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
  get: async (id: number): Promise<IReportHeaderTemplatesGetResponse> => {
    return transformResponse(await axios.get(
      reportHeaderTemplatesUrl() + `/${id}`
    ))
  },
  getAll: async (): Promise<IReportHeaderTemplatesGetAllResponse> => {
    return transformResponse(await axios.get(
      reportHeaderTemplatesUrl()
    ))
  },
  update: async (id: number, request: IReportHeaderTemplatesAddRequest): Promise<IReportHeaderTemplatesGetResponse> => {
    const formData = new FormData();
    for(const [key, val] of Object.entries(request)) {
      if (val)
      formData.append(key, val)
    }
    return transformResponse(await axios.post(
      reportHeaderTemplatesUrl() + `/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
  delete: async (id: number): Promise<IReportHeaderTemplatesGetResponse> => {
    return transformResponse(await axios.delete(
      reportHeaderTemplatesUrl() + `/${id}`
    ))
  },
}
export const projects = {
  get,
  getByCompany,
  getAllLatest,
  add,
  update,
  delete: deleteP,
  download,
  generateDownloadZip,
  getFSNUploadProcessingStatus,
  areaOfInterests,
  reports,
  reportHeaderTemplates
}
export * from './models'