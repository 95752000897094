
import { AssociateRoles, IAssociate } from "@/api";
import { navs, permissions } from "@/mixins";
import { Actions, Mutations } from "@/store/models";
import Vue from "vue";
import UserIcon from "../common/UserIcon.vue";

export default Vue.extend({
  components: { UserIcon },
  name: "AppBar",
  props: {
    drawer: Boolean,
  },
  mixins: [navs, permissions],
  data() {
    return {
      logo: require("/src/assets/gssi_logo_gradientorange.svg"),
      AssociateRoles: AssociateRoles,
    };
  },
  computed: {
    associate(): IAssociate {
      return this.$store.state.associate;
    },
    associateName(): string {
      return this.$store.getters.associateName;
    },
    roles(): Set<AssociateRoles> {
      return this.$store.getters.associateRoles;
    },
    storage(): any {
      return {
        used: this.$store.state.company?.account?.license_info.total_storage_used,
        total: this.$store.state.company?.account?.license_info.total_storage_limit,
        percent: this.$store.state.company?.account?.license_info.percent_used,
      };
    },
    storageProgress(): number {
      return this.storage.percent;
    },
  },
  methods: {
    dashboard() {
      (this as any).goToInitial();
    },
    profileSettings() {
      this.$router.push("/users/" + this.$store.state.associate.id);
    },
    accountManagement() {
      if ((this as any).isGssiAdmin) {
        this.$router.push("/gssiadmin/accountmanagement");
      } else {
        this.$router.push("/accountmanagement");
      }
    },
    reportHeaderTemplates() {
      this.$router.push("/reportHeaderTemplates");
    },
    async logout() {
      await this.$store.dispatch(Actions.Logout);
    },
    support() {
      this.$store.commit(Mutations.SetSupportDialog, true);
    },
    isActive(item: string): string {
      const path = this.$route.name;
      if (path === item) {
        return "active-menu";
      }
      return "";
    },
  },
  watch: {
    drawer() {
      document.getElementById("left-icon")!.style.visibility = this.drawer
        ? "visible"
        : "hidden";
    },
  },
});
