import axios, { AxiosResponse } from 'axios'
import store from '@/store'
import { Mutations } from '@/store/models'

export class Api {
  public static isLoading = false
}
const setupInterceptors = () => {
  axios.interceptors.request.use((request) => {
    // hide loader on certain api calls
    if (request.url === "/api/v1/projects/upload_flex") {
      return request
    }
    store.commit(Mutations.SetIsLoading, true)
    return request
  }, (error) => {
    store.commit(Mutations.SetIsLoading, false)
    return Promise.reject(error)
  })
  axios.interceptors.response.use((response) => {
    store.commit(Mutations.SetIsLoading, false)
    return response
  }, (error) => {
    store.commit(Mutations.SetIsLoading, false)
    return Promise.reject(error)
  })
}
setupInterceptors()

export const getDomain = (): string => {
  return '';
}

export const updateBaseUrl = (options?: { public?: boolean }): string => {
  let base = getDomain()
  // switch (process.env.NODE_ENV) {
  //   case 'development':
  //     base += ''
  //     break
  //   default:
  //     base += process.env.VUE_APP_ROOT_API
  //     break
  // }
  if (options?.public) {
    base += '/public'
  }
  base += '/api/v1'
  baseUrl = base
  return base
}
/* eslint no-var: off */
export var baseUrl = updateBaseUrl()

export const setHeaders = (headers: { key: HeaderKeys, value: string }[]) => {
  if (headers && headers.length) {
    headers.forEach((header) => {
      axios.defaults.headers.common[header.key] = header.value
    })
  }
}
export const removeHeader = (header: HeaderKeys) => {
  delete axios.defaults.headers.common[header];
}
export const setAuthTokenHeader = (authtoken: string) => {
  axios.defaults.headers.common[HeaderKeys.AuthToken] = authtoken
}

export const transformResponse = (response: AxiosResponse): any => {
  if (response.data.status.code <= 2001 || response.data.status.status === Status.Success) {
    return response.data
  }
  throw { 
    statusCode: response.status,
    statusText: response.statusText,
    response: { 
      data: { 
        message: response.data?.status?.message || "service error", 
      },
      status: response.data?.status?.code
    }
  }
}

export const multiApiOperations = (api: (id: number) => Promise<any>, ids: number[]): Promise<any> => {
  const apiCalls: any = [];
  [...ids].forEach((id) => {
    apiCalls.push(api(id));
  });
  return Promise.all(apiCalls);
}

export enum HeaderKeys {
  AuthToken = 'authtoken',
  Password = 'password',
  ForceActivateCurrentUserSession = 'force-activate-current-user-session'
}
export enum Status {
  Success = 'success'
}
export interface IListInfo {
  start_index: number,
  row_count: number,
  has_more_rows: boolean
  total_count?: number
  search_fields?: any
  page_limit?: number
}
export interface IResponseStatus {
  code?: number,
  status?: string,
  message?: string
}
export interface IBaseResponse {
  status: IResponseStatus
  list_info?: IListInfo
}
export interface IErrorResponse {
  response: {
    data: {
      message?: string
      status?: {
        message: string
      }
    }
  }
}
