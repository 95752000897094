import axios, { AxiosResponse } from 'axios'
import { baseUrl, IBaseResponse, transformResponse } from "..";
import { AttachmentsEntities, AttachmentsProjectFileTypes, AttachmentsScanFileTypes, IAttachmentsGetAllResponse } from "./models";
import store from '@/store';
import { Mutations, ProgressPanelState } from "@/store/models";

const attachmentsUrl = () => baseUrl + '/attachments'
const update = async (id: number, request: any): Promise<IBaseResponse> => {
  const formData = new FormData();
  for (const [key, val] of Object.entries(request) as any) {
    if (val !== undefined)
      formData.append(key, val)
  }

  return transformResponse(await axios.put(
    attachmentsUrl() + "/" + id,
    formData
  ))
}
const deleteA = async (id: number): Promise<IBaseResponse> => {
  return transformResponse(await axios.delete(
    attachmentsUrl() + '/' + id,
  ))
}
const getPresignedDownloadUrl = async (id: number): Promise<IBaseResponse & {
  data: { presignedUrl: string }
}> => {
  return transformResponse(await axios.get(
    attachmentsUrl() + '/' + id + "/pre_signed_download_url?download=true"
  ))
}
const downloadFromPresignedUrl = async (id: number) => {
  const presignedUrl = (await getPresignedDownloadUrl(id))?.data?.presignedUrl
  console.log(presignedUrl)
  const link = document.createElement('a');
  link.href = presignedUrl;
  link.download = `file_${id}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // short delay to allow the download to start from browser
  await new Promise(resolve => setTimeout(resolve, 1000));
}

// deprecated
const download = async (id: number) => {
  const response: any = await axios.get(
    attachmentsUrl() + '/' + id + "/download",
    {
      responseType: 'arraybuffer',
    }
  )
  const disposition = response.headers['content-disposition']
  const fileName = disposition?.split('filename=')?.[1] || 'file'
  const type = response.headers['content-type']
  const blob = await new Blob([response.data], { type });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const getImageFromPresignedUrl = async (id: number): Promise<IBaseResponse & {
  data: { presignedUrl: string }
}> => {
  return transformResponse(await axios.get(
    attachmentsUrl() + '/' + id + "/pre_signed_download_url"
  ))
}

// deprecated
const getImage = async (id: number): Promise<any> => {
  return axios.get(
    attachmentsUrl() + '/' + id + "/download",
    {
      responseType: 'arraybuffer',
    }
  )
}

const processFileUpload = async (file: File, request: any, additionalInfo: any): Promise<AxiosResponse<any>> => {
  if (!file) {
    return Promise.reject('No file selected');
  }
  // Check if the file is larger than 3MB
  if (file.size > 3000 * 1000) {
    // File is larger than 3MB, upload to S3 using presigned URL
    return await uploadLargeFile(file, request, additionalInfo);
  } else {
    // File is smaller than 3MB, upload directly to the server
    return await uploadSmallFile(file, request, additionalInfo);
  }
}

const uploadLargeFile = async (file: File, request: any, additionalInfo: any): Promise<AxiosResponse<any>> => {
  try {
    // Get a pre-signed URL from the backend
    const fileName = file.name;
    const fileType = file.type;
    const formData = new FormData();
    if (additionalInfo.entity) {
      formData.append('entity', additionalInfo.entity);
    }
    if (additionalInfo.entity_id) {
      formData.append('entity_id', additionalInfo.entity_id)
    }
    if (additionalInfo.file_type) {
      formData.append('file_type', additionalInfo.file_type)
    }
    if (request && request.projects_areas_of_interest_name) {
      formData.append('projects_areas_of_interest_name', request.projects_areas_of_interest_name);
    }
    formData.append('file_name', fileName)
    formData.append('file_content_type', fileType);
    const dataToGenerateLink = {
      'entity': additionalInfo.entity,
      'entity_id': additionalInfo.entity_id,
      'file_type': additionalInfo.file_type,
      'file_name': fileName,
      'file_content_type': fileType
    }

    console.log('File uploaded link generation started :: ' + dataToGenerateLink);
    // const response = await fetch(`http://localhost:3000/generate-presigned-url?fileName=${fileName}&fileType=${fileType}`);
    const response = transformResponse(
      await axios.post(baseUrl + '/projects/attachments/generate_upload_link', dataToGenerateLink, { headers: { "Content-Type": "application/json" } }));
    console.log('File uploaded link generation completed :: response :: ' + JSON.stringify(response));
    if (response.data) {
      const url = response.data.s3_presigned_url;
      console.log('File uploaded link generation completed :: url && file :: ' + (url && file));
      if (url && file) {
        // Upload the file using the pre-signed URL
        const result = await uploadFileToS3(url, file);
        if (result && result.status >= 200 && result.status < 300) {
          console.log('File uploaded to S3 successfully!');
          // display fsn processing toast if file is fsn
          checkFSNFile(file, response.data.id)
          formData.append('uploads_queue_id', response.data.id);
          // return await axios.post(baseUrl+'/projects/attachments/uploads_queue/'+response.data.id, formData, { headers: { "Content-Type": "application/json"}});
          return await axios.post(additionalInfo.url, formData, { headers: { "Content-Type": "application/json" } });
        }
      }
      else {
        console.log('File uploaded link generation completed :: url && file :: ' + (url && file));
      }
      return Promise.reject('File upload to S3 failed.');
    }
    else {
      return Promise.reject('Failed to generate the link to upload file.');
    }
  } catch (error) {
    console.error('Error uploading large file:', error);
    return Promise.reject('Error uploading large file.');
  }
}

const checkFSNFile = (file: File, uploadsQueueId: number) => {
  if (file.name.split(".").pop() === "fsn") {
    store.commit(Mutations.SetFSNProcessingToast, uploadsQueueId)
  }
}

const uploadSmallFile = async (file: File, request: any, additionalInfo: any): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(
      additionalInfo.url,
      additionalInfo.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    )
  } catch (error) {
    console.error('Error uploading small file:', error);
    return Promise.reject('Required infomation is not available.');
  }
}

const uploadFileToS3 = async (s3Url: string, file: any) => {
  return axios.put(s3Url, file, {
    headers: {
      'Content-Type': file.type || "application/octet-stream",  // Ensure correct content type
    },
    onUploadProgress: (event: any) => {
      const progress = Math.round((event.loaded * 100) / event.total);
      console.log('progress :: '+progress);
      store.commit(Mutations.SetProgressPanel, {
        text: `Uploading : ${progress}%`,
        state: ProgressPanelState.InProgress,
        progress: progress,
      });
    },
  }).then((response : AxiosResponse) => {
    if (response.status >= 200 && response.status < 300) {
      store.commit(Mutations.SetProgressPanel, {
        text: "Project Upload Completed",
        state: ProgressPanelState.Completed,
      });
      return response;
    } else {
      store.commit(Mutations.SetProgressPanel, {
        text: "Project Upload Failed",
        state: ProgressPanelState.Failed,
      });
      throw new Error('Failed to upload file to S3');
    }
  }).catch((error: any) => {
    console.error('Error uploading file:', error);
    throw error;
  });
}


const reorder = async (pid: number, order: number[]): Promise<IBaseResponse> => {
  // const formData = new FormData();
  // formData.append('entity', 'projects')
  // formData.append('entity_id', pid.toString())
  // formData.append('ids', order as any)

  return transformResponse(await axios.put(
    baseUrl + '/reorder/attachments', {
    'entity': 'projects',
    'entity_id': pid,
    'ids': order
  }
  ))
}

const projects = {
  getAll: async (projectId: number, projects_areas_of_interest_id?: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        get_total_count: true,
        search_fields: {
          entity: AttachmentsEntities.Projects,
          entity_id: projectId,
          projects_areas_of_interest_id
        }
      }
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: {
    data: File,
    projects_areas_of_interest_name: string,
    title?: string,
    description?: string
    file_type?: AttachmentsProjectFileTypes,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Projects)
    formData.append('entity_id', projectId.toString())
    formData.append('file_type', request.file_type || AttachmentsProjectFileTypes.SitePhotos)

    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }
    const additionalInfo = {
      'entity': AttachmentsEntities.Projects,
      'entity_id': projectId.toString(),
      'file_type': request.file_type || AttachmentsProjectFileTypes.SitePhotos,
      'url': attachmentsUrl(),
      'formData': formData
    }

    // return transformResponse(await axios.post(
    //   attachmentsUrl(),
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data"
    //     }
    //   }
    // ))

    return transformResponse(await processFileUpload(request.data, request, additionalInfo));

  },
  uploadScan: async (projectId: number, request: {
    data: File,
    projects_areas_of_interest_name: string,
    title?: string,
    description?: string
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Projects)
    formData.append('entity_id', projectId.toString())
    for (const [key, val] of Object.entries(request)) {
      console.log('uploadScan :: ' + key);
      if (val)
        formData.append(key, val)
    }
    return transformResponse(await axios.post(
      baseUrl + '/projects/upload_attachments',
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
  uploadFSNZip: async (request: {
    data: File,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('zip-file', request.data);

    // return transformResponse(await axios.post(
    //   baseUrl + '/projects/upload_flex',
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data"
    //     }
    //   }
    // ))

    // console.log('uploadFSNZip :: filetype :: '+request.data.type);
    const additionalInfo = {
      'url': baseUrl + '/projects/upload_flex',
      'formData': formData
    }
    return transformResponse(await processFileUpload(request.data, undefined, additionalInfo));
  }
}

const scans = {
  getAll: async (scanId: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        get_total_count: true,
        search_fields: {
          entity: AttachmentsEntities.Scans,
          entity_id: scanId
        }
      }
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (scanId: number, file: File, fileType: AttachmentsScanFileTypes): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Scans)
    formData.append('entity_id', scanId.toString())
    formData.append('data', file);
    formData.append('file_type', fileType)
    // return transformResponse(await axios.post(
    //   attachmentsUrl(),
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data"
    //     }
    //   }
    // ))

    const additionalInfo = {
      'entity': AttachmentsEntities.Scans,
      'entity_id': scanId.toString(),
      'file_type': fileType,
      'url': attachmentsUrl(),
      'formData': formData
    }
    return transformResponse(await processFileUpload(file, undefined, additionalInfo));

  },
}

const reports = {
  getAll: async (projectId: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        get_total_count: true,
        search_fields: {
          entity: AttachmentsEntities.ProjectReports,
          entity_id: projectId
        }
      },
      // row_count: 100
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: {
    data: File,
    name?: string,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.ProjectReports)
    formData.append('entity_id', projectId.toString())

    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }

    return transformResponse(await axios.post(
      attachmentsUrl(),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))

    // let additionalInfo = {
    //   'entity' : AttachmentsEntities.ProjectReports,
    //   'entity_id' : projectId.toString(),
    //   'url' : attachmentsUrl(),
    //   'formData' : formData
    // }

    // return transformResponse(await processFileUpload(request.data, request, additionalInfo));

  },
}

const documents = {
  getAll: async (projectId: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          entity: AttachmentsEntities.Projects,
          entity_id: projectId,
          "projects_areas_of_interest_id": null
        }
      }
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: {
    data: File,
    title?: string,
    description?: string
    file_type?: AttachmentsProjectFileTypes,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Projects)
    formData.append('entity_id', projectId.toString())
    formData.append('file_type', AttachmentsProjectFileTypes.ProjectDocuments)

    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }

    const additionalInfo = {
      'entity': AttachmentsEntities.Projects,
      'entity_id': projectId.toString(),
      'file_type': request.file_type || AttachmentsProjectFileTypes.ProjectDocuments,
      'url': attachmentsUrl(),
      'formData': formData
    }

    // return transformResponse(await axios.post(
    //   attachmentsUrl(),
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data"
    //     }
    //   }
    // ))

    return transformResponse(await processFileUpload(request.data, request, additionalInfo));
  },

}
export const attachments = {
  update,
  delete: deleteA,
  downloadFromPresignedUrl,
  download,
  getImageFromPresignedUrl,
  getImage,
  reorder,

  projects,
  scans,
  reports,
  documents
}
export * from "./models"