
import Vue from "vue";

export default Vue.extend({
  name: "Logo",
  props: {
    size: Number
  },
  data() {
    return {
      logo: require("/src/assets/gssi-emblem.svg")
    };
  },
});
