
import Vue from "vue";
import { Actions, Mutations } from "@/store/models";
import { ISupportRequest, support } from "@/api";

export default Vue.extend({
  name: "SupportDialog",
  data() {
    return {
      form: {} as ISupportRequest,
      confirmationDialog: false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.supportDialog;
      },
      set(val) {
        return this.$store.commit(Mutations.SetSupportDialog, val);
      },
    },
  },
  methods: {
    close() {
      this.form = {} as any;
      this.$store.commit(Mutations.SetSupportDialog, false);
    },
    async submit() {
      try {
        if (!this.form.subject) {
          return;
        }
        if (this.$route.name === "Project") {
          this.form.project_id = this.$route.params.id;
        }
        this.form.to = process.env.VUE_APP_SUPPORT_CONTACT;
        await support.add(this.form);
        this.close();
        this.confirmationDialog = true;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    goToResources() {
      window.open(
        "https://www.geophysical.com/support#helpful-resources",
        "_blank"
      );
    },
  },

  watch: {},
});
