import axios from "axios"
import { baseUrl, transformResponse } from "../common"
import * as queryString from 'query-string'
import { AssociateStatus, IAssociateAddRequest, IAssociateInviteRequest, IAssociatesGetResponse, IAssociatesUpdateResponse, IAssociateUpdateRequest, IGetAccessTokenResponse, IGroupAddRequest, IGroupAddResponse, IGroupGetAllResponse, IGroupUsersResponse } from "./models"


export const getAccessToken = async (code: string): Promise<IGetAccessTokenResponse> => {
  const request = queryString.stringify({
    grant_type: 'authorization_code',
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URL,
    code
  })
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  return axios.post(
    process.env.VUE_APP_ACCESS_TOKEN_URL,
    request,
    {
      headers
    }
  )
}

const logoutUrl = () => baseUrl + '/logout'
export const logout = async (): Promise<IAssociatesGetResponse> => {
  return transformResponse(await axios.post(
    logoutUrl()
  ))
}


const associatesUrl = () => baseUrl + '/associates'
export const getUserInfo = async (): Promise<IAssociatesGetResponse> => {
  return transformResponse(await axios.get(
    associatesUrl() + '/user_info'
  ))
}

export const get = async (id: number): Promise<IAssociatesGetResponse> => {
  return transformResponse(await axios.get(
    `${associatesUrl()}/${id}`
  ))
}

export const getAllByCompany = async (
  companyId: number,
  list_info?: { start_index?: number, sort_field?: string, sort_order?: string, row_count?: number }
): Promise<IAssociatesGetResponse> => {
  const params = {
    list_info: {
      search_fields: {
        company_id: companyId
      },
      ...list_info
    }
  } as any

  return transformResponse(await axios.get(
    associatesUrl(),
    {
      params
    }
  ))
}

export const add = async (request: IAssociateAddRequest): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.post(
    `${associatesUrl()}/company_user`,
    request
  ))
}

export const update = async (id: number, request: IAssociateUpdateRequest): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.put(
    `${associatesUrl()}/company_user/${id}`,
    request
  ))
}
export const updateProfile = async (id: number, request: IAssociateUpdateRequest): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.put(
    `${associatesUrl()}/${id}/updateProfile`,
    request
  ))
}
export const updateAssociatedGroup = async (id: number, request: IAssociateUpdateRequest): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.put(
    `${associatesUrl()}/company_user/${id}/associate_group`,
    request
  ))
}

const deleteUser = async (id: number): Promise<any> => {
  return transformResponse(await axios.delete(
    `${associatesUrl()}/company_user/${id}`,
  ))
}

export const updateStatus = async (id: number, status: AssociateStatus): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.put(
    `${associatesUrl()}/${id}/updateStatus?action=${status}`,
  ))
}

export const uploadProfilePic = async (id: number, file: File): Promise<IAssociatesUpdateResponse> => {
  const formData = new FormData();
  formData.append('profile_pic', file);
  return transformResponse(await axios.put(
    `${associatesUrl()}/${id}/uploadProfilePic`,
    formData
  ))
}

export const invite = async (request: IAssociateInviteRequest): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.post(
    `${associatesUrl()}/invite_user`,
    request
  ))
}
export const reinvite = async (id: number): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.put(
    `${associatesUrl()}/${id}/reinviteUser`,
  ))
}

export const updateTerms = async (id: number, user_agreement: boolean): Promise<IAssociatesUpdateResponse> => {
  return transformResponse(await axios.put(
    `${associatesUrl()}/${id}/updateTerms`, {
    user_agreement
  }
  ))
}

const gssi = {
  invite: async (request: IAssociateInviteRequest): Promise<IAssociatesUpdateResponse> => {
    return transformResponse(await axios.post(
      `${associatesUrl()}/invite_user/gssi_non_admin_user`,
      request
    ))
  }
}

/****************** GROUPS *****************/
const groupsUrl = () => baseUrl + '/associates_groups'
const getAllGroupsByCompany = async (companyId: number): Promise<IGroupGetAllResponse> => {
  const params = {
    list_info: {
      search_fields: {
        company_id: companyId
      }
    }
  }
  return transformResponse(await axios.get(
    groupsUrl(),
    {
      params
    }
  ))
}
const deleteGroup = async (id: number): Promise<any> => {
  return transformResponse(await axios.delete(
    groupsUrl() + '/' + id,
  ))
}
export const addGroup = async (request: IGroupAddRequest): Promise<IGroupAddResponse> => {
  return transformResponse(await axios.post(
    groupsUrl(),
    request
  ))
}
export const updateGroup = async (id: number, request: { name?: string }): Promise<IGroupAddResponse> => {
  return transformResponse(await axios.put(
    groupsUrl() + '/' + id,
    request
  ))
}

const groupUsersUrl = () => baseUrl + '/group_users'
export const getGroupUsers = async (
  groupId: number
): Promise<IGroupUsersResponse> => {
  return transformResponse(await axios.get(
    groupUsersUrl() + `/${groupId}`
  ))
}


export const associates = {
  logout,
  getAccessToken,
  getUserInfo,
  get,
  getAllByCompany,
  add,
  update,
  updateProfile,
  updateAssociatedGroup,
  delete: deleteUser,
  updateStatus,
  uploadProfilePic,
  invite,
  reinvite,
  updateTerms,

  getAllGroupsByCompany,
  deleteGroup,
  addGroup,
  updateGroup,

  gssi
}
export * from './models'
