import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'



Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Splash',
    component: () => import('../views/SignUp_Login/Login.vue')
  },
  {
    path: '/login/suspended',
    name: 'Suspended',
    component: () => import('../views/SignUp_Login/AccountSuspended.vue')
  },
  {
    path: '/login/billing',
    name: 'LoginBilling',
    component: () => import('../views/SignUp_Login/LoginBilling.vue')
  },
  {
    path: '/signup/accounttransferterms',
    name: 'AccountTransferTerms',
    component: () => import('../views/SignUp_Login/AccountTransferTerms.vue')
  },

  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp_Login/SignUp.vue')
  },
  {
    path: '/signup/termsandconditions',
    name: 'SignupTermsAndConditions',
    component: () => import('../views/SignUp_Login/SignUpTermsAndConditions.vue')
  },
  {
    path: '/signup/privacypolicy',
    name: 'SignupPrivacyPolicy',
    component: () => import('../views/SignUp_Login/SignupPrivacyPolicy.vue')
  },
  {
    path: '/verifyemail',
    name: 'VerifyEmail',
    component: () => import('../views/SignUp_Login/VerifyEmail.vue')
  },
  {
    path: '/signupteam',
    name: 'SignupTeam',
    component: () => import('../views/SignUp_Login/SignupTeam.vue')
  },
  // currently used as a component within SignupTeam view
  // {
  //   path: '/signup/useragreement',
  //   name: 'SignupUserAgreement',
  //   component: () => import('../views/SignUp_Login/SignupUserAgreement.vue')
  // },
  {
    path: '/signup/invitemember',
    name: 'SignupInviteMember',
    component: () => import('../views/SignUp_Login/SignupInviteMember.vue')
  },
  {
    path: '/signup/navigate',
    name: 'SignupNavigate',
    component: () => import('../views/SignUp_Login/SignupNavigate.vue')
  },
  // {
  //   path: '/registration',
  //   name: 'Registration',
  //   component: () => import('../views/SignUp_Login/old/Registration.vue')
  // },
  {
    path: '/jointeam',
    name: 'JoinTeam',
    component: () => import('../views/SignUp_Login/JoinTeam.vue')
  },

  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('../components/login/ForgotPassword.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/equipment',
    name: 'Equipment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Equipment.vue')
  },
  {
    path: '/equipment/:id',
    name: 'EquipmentProfile',
    component: () => import('../components/equipment/profile/EquipmentProfile.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/projects/:id',
    name: 'Project',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/projects/:id/scan/:scanId',
    name: 'Scan',
    component: () => import('../components/project/scan/Scan.vue')
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('../views/Groups.vue')
  },
  {
    path: '/groups/:groupId',
    name: 'GroupsProfile',
    component: () => import('../components/groups/groupProfile/GroupProfile.vue')
  },
  {
    path: '/groups/:groupId/users',
    name: 'GroupUsers',
    component: () => import('../components/groups/users/Users.vue')
  },
  {
    path: '/groups/:groupId/users/:userId',
    name: 'GroupUserProfile',
    component: () => import('../components/groups/users/UserProfile.vue')
  },
  {
    path: '/users/:userId',
    name: 'UserProfile',
    component: () => import('../components/groups/users/UserProfile.vue')
  },
  {
    path: '/reportHeaderTemplates',
    name: 'ReportHeaderTemplates',
    component: () => import('../views/ReportHeaderTemplates.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue')
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: () => import('../components/reports/report/Report.vue')
  },
  {
    path: '/reports/:id/pdf',
    name: 'ReportPdf',
    component: () => import('../components/reports/report/pdf/ReportPdf.vue')
  },
  {
    path: '/accountmanagement',
    name: 'AccountManagement',
    component: () => import('../views/AccountManagement.vue')
  },
  {
    path: '/accountplan',
    name: 'AccountPlan',
    component: () => import('../views/AccountPlan.vue')
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import('../components/accountManagement/billing/Billing.vue')
  },
  {
    path: '/billingedit',
    name: 'BillingEdit',
    component: () => import('../components/accountManagement/billing/BillingEdit.vue')
  },
  {
    path: '/invoice/:id',
    name: 'InvoicePdf',
    component: () => import('../components/accountManagement/billing/InvoicePdf.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/Support.vue')
  },
  {
    path: '/scans',
    name: 'ManageScans',
    component: () => import('../views/deprecated/ManageScans.vue')
  },
  {
    path: '/cloudfolder',
    name: 'CloudFolder',
    component: () => import('../views/deprecated/CloudFolder.vue')
  },
  {
    path: '/sampleWebGL',
    name: 'SampleWebGL',
    component: () => import('../views/deprecated/SampleWebGL.vue')
  },
  {
    path: '/public',
    component: () => import('../views/Public.vue'),
    name: 'Public',
    children: [
      { path: 'report/:token', component: () => import('../components/reports/report/Report.vue') },
      { path: 'report/:token/pdf', component: () => import('../components/reports/report/pdf/ReportPdf.vue') }
    ]
  },

  // admin 
  {
    path: '/gssiadmin',
    component: () => import('../views/GSSIAdmin/GssiAdmin.vue'),
    name: 'GssiAdmin',
    children: [
      { name: "GssiAdminJoinTeam", path: 'jointeam', component: () => import('../views/SignUp_Login/JoinTeam.vue') },
      { name: "GssiAdminAccountManagement", path: 'accountmanagement', component: () => import('../views/AccountManagement.vue') },

      { name: "GssiAdminAccounts", path: 'accounts', component: () => import('../views/GSSIAdmin/accounts/Accounts.vue') },
      { name: "GssiAdminCompanyDashboard", path: ':companyId/dashboard', component: () => import('../views/GSSIAdmin/accounts/CompanyDashboard.vue') },
      { name: "GssiAdminCompanyAccountManagement", path: ':companyId/accountmanagement', component: () => import('../views/AccountManagement.vue') },
      { name: "GssiAdminAccountPlan", path: ':companyId/accountplan', component: () => import('../views/AccountPlan.vue') },
      { name: "GssiAdminBilling", path: ':companyId/billing', component: () => import('../components/accountManagement/billing/Billing.vue') },
      { name: "GssiAdminBillingEdit", path: ':companyId/billingedit', component: () => import('../components/accountManagement/billing/BillingEdit.vue') },

      { name: "GssiAdminCompanyGroups", path: ':companyId/groups', component: () => import('../views/Groups.vue') },
      { name: "GssiAdminCompanyGroupsUsers", path: ':companyId/groups/:groupId/users', component: () => import('../components/groups/users/Users.vue') },


      { name: "GssiAdminUsers", path: 'users', component: () => import('../views/GSSIAdmin/users/Users.vue') },
      { name: "GssiAdminUserProfile", path: ':companyId/users/:userId', component: () => import('../components/groups/users/UserProfile.vue') },

      { name: "GssiAdminProjects", path: 'projects', component: () => import('../views/GSSIAdmin/projects/SharedProjects.vue') },
      { name: "GssiAdminCompanyProject", path: ':companyId/projects/:id', component: () => import('../views/Project.vue') },

      { name: "GssiAdminEquipment", path: 'equipment', component: () => import('../views/GSSIAdmin/equipment/Equipment.vue') }

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
