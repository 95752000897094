import axios from 'axios'
import { IDashboardActivityLogGetResponse, IDashboardGroupProjectsGetResponse, IDashboardLocationsGetResponse, } from '..'

import { baseUrl, transformResponse } from '../common'
import { DashboardTimePeriod, IDashboardEquipmentGetResponse, IDashboardGetResponse } from './models'

const dashboardUrl = () => baseUrl + '/dashboard'

const projects = {
  getTotalByTimeAndMore: async (timePeriod: DashboardTimePeriod, options?: { groupId?: number, deviceId?: number, companyId?: number }): Promise<IDashboardGetResponse> => {
    const params = {
      time_period: timePeriod,
    } as any
    if (options?.groupId !== undefined) {
      params.group_id = options.groupId
    }
    if (options?.deviceId) {
      params.device_id = options.deviceId
    }
    if (options?.companyId) {
      params.company_id = options.companyId
    }
    return transformResponse(await axios.get(
      dashboardUrl() + '/projects/created_datetime',
      {
        params
      }
    ))
  },
  async getActivityLogs(companyId?: number): Promise<IDashboardActivityLogGetResponse> {
    return transformResponse(await axios.get(
      baseUrl + '/project_activities' + (companyId ? `?company_id=${companyId}` : ''),
    ))
  }
}

const equimentsUrl = () => baseUrl + '/equipments_by_device_group'
const equipments = {
  getAll: async (companyId?: number): Promise<IDashboardEquipmentGetResponse> => {
    const params = {
      company_id: companyId,
    } as any
    return transformResponse(await axios.get(
      equimentsUrl(),
      {
        params
      }
    ))
  },
  getByGroup: async (groupId: number, companyId?: number): Promise<IDashboardEquipmentGetResponse> => {
    const params = {
      company_id: companyId,
    } as any
    return transformResponse(await axios.get(
      equimentsUrl() + '/' + groupId,
      {
        params
      }
    ))
  }
}

const group = {
  async getActivityLogs(id: number): Promise<IDashboardActivityLogGetResponse> {
    return transformResponse(await axios.get(
      baseUrl + '/group_activities/' + id,
    ))
  },
  async getLocations(id: number): Promise<IDashboardLocationsGetResponse> {
    return transformResponse(await axios.get(
      baseUrl + '/group_locations/' + id,
    ))
  },
  async getScansByTimeAndMore(timePeriod: DashboardTimePeriod, options?: { groupId?: number, deviceId?: number }): Promise<IDashboardGetResponse> {
    const params: any = {
      time_period: timePeriod,
    }
    const { groupId, deviceId } = options || {}
    if (groupId) {
      params.group_id = groupId
    }
    if (deviceId) {
      params.device_id = deviceId
    }
    return transformResponse(await axios.get(
      dashboardUrl() + '/scans/created_datetime',
      {
        params
      }
    ))
  },

  async getLatestProjects(id: number): Promise<IDashboardGroupProjectsGetResponse> {
    const params = {
      list_info: {
        "sort_field": "updated_datetime", "sort_order": "DESC", "get_total_count": true
      }
    }
    return transformResponse(await axios.get(
      baseUrl + '/group_projects/' + id,
      {
        params
      }
    ))
  }
}

export const dashboard = {
  projects,
  equipments,
  group
}
export * from './models'