
import Vue from "vue";

export default Vue.extend({
  name: "Loader",
  data() {
    return {};
  },
  computed: {
    isLoading: function (): boolean {
      return this.$store.state.isLoading;
    },
    forceLoading: function (): boolean {
      return this.$store.state.forceLoading;
    },
    loading: function (): boolean {
      return this.isLoading || this.forceLoading
    }
  },
  methods: {},
  watch: {
    loading: {
      deep: true,
      handler() {
        // console.log(this.loading);
      },
    },
  },
});
