import axios from 'axios'
import { baseUrl, transformResponse } from '../common'
import { IDevicesGetAllResponse, IProjectsByDeviceResponse } from './models'

const devicesUrl = () => baseUrl + '/devices'

const get = async (id: number):Promise<IDevicesGetAllResponse> => {
  return transformResponse(await axios.get(
    devicesUrl() + '/' + id
  ))
}

const getAll = async (list_info?: { start_index?: number }):Promise<IDevicesGetAllResponse> => {
  const params = {
    list_info
  }
  return transformResponse(await axios.get(
    devicesUrl(),
    {
      params
    }
  ))
}

const getAllByCompany = async (companyId: number):Promise<IDevicesGetAllResponse> => {
  const params = {
    list_info: {
      get_total_count: true,
      search_fields: {
        company_id: companyId
      }
    }
  };
  return transformResponse(await axios.get(
    devicesUrl(),
    { params }
  ))
}

const getProjectsByDevice = async (deviceId: number):Promise<IProjectsByDeviceResponse> => {
  return transformResponse(await axios.get(
    devicesUrl() + `/${deviceId}/projects`,
  ))
}


export const devices = {
  get,
  getAll,
  getAllByCompany,
  getProjectsByDevice
}
export * from './models'

