import { IAssociate, IDevice, IScan, scans } from "@/api";
import { Module } from "vuex";
import { Actions } from "../models";

const scansFilterModule: Module<any, any> = {
  namespaced: true,
  state: {
    scans: [],
    filteredScans: [],
    timePeriod: undefined,
    radar: undefined,
    user: undefined
  } as IScansFilterState,

  getters: {
  },

  mutations: {
    setScans(state, scans) {
      state.scans = scans
    },
    setFilteredScans(state, scans) {
      state.filteredScans = scans
    },
    setTimePeriod(state, val) {
      state.timePeriod = val
    },
    setRadar(state, val) {
      state.radar = val
    },
    setUser(state, val) {
      state.user = val
    }
  },

  actions: {
    async filterScans({ state, getters, dispatch, commit }, scansList?: IScan[]) {
      if (scansList) {
        commit(ScansFilterMutations.SetScans, scansList, { root: true })
      }
      let filteredScans = state.scans
      try {
       
        if (state.timePeriod) {
          const { data } = await scans.getByTimePeriod(state.timePeriod.id)
          filteredScans = filteredScans.filter((scan: IScan) =>
            data.find(s => s.id === scan.id)
          )
        }
        if (state.radar) {
          filteredScans = filteredScans.filter((scan: IScan) =>
            scan.device.id === state.radar.id
          )
        }
        if (state.user) {
          filteredScans = filteredScans.filter((scan: IScan) =>
            scan.created_by === state.user.user_name
          )
        }
      } catch (e) {
        dispatch(Actions.DisplayError, e, { root: true });
      }
      commit(ScansFilterMutations.SetFilteredScans, filteredScans, { root: true })
    },

    async onFilterChange({ state, getters, dispatch, commit }, { type, data }: { type: ScansFilterMutations, data: any }) {
      console.log(type, data)
      commit(type, data, { root: true })
      dispatch(ScansFilterActions.FilterScans, undefined, { root: true })
    },

  }
};

export default scansFilterModule;

interface IScansFilterState {
  scans: IScan[],
  timePeriod?: { id: string, name: string },
  radar?: IDevice,
  user?: IAssociate
}


export enum ScansFilterMutations {
  SetScans = 'scansFilter/setScans',
  SetFilteredScans = 'scansFilter/setFilteredScans',
  SetTimePeriod = 'scansFilter/setTimePeriod',
  SetRadar = 'scansFilter/setRadar',
  SetUser = 'scansFilter/setUser'
}

export enum ScansFilterActions {
  FilterScans = 'scansFilter/filterScans',
  OnFilterChange = 'scansFilter/onFilterChange',
}



