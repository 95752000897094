
import { AssociateAction, AssociateType, LicenseType } from "@/api";
import { isExpiredCard } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "Banner",
  data() {
    return {};
  },
  computed: {
    proUpgrade: function (): boolean {
      return (
        this.$store.getters.licenseType === LicenseType.FreeTrial &&
        this.$store.state.associate?.associate_type?.name ===
          AssociateType.CompanyAccountOwner &&
        !this.$route.path.includes("pdf") &&
        !this.$route.path.includes("accountplan") &&
        !this.$route.path.includes("accountmanagement")
      );
    },
    daysLeft: function (): number {
      return this.$store.getters.freeTrailDaysLeft;
    },
    expiringCard: function (): boolean {
      if (
        this.$store.state.associate?.associate_type?.name ===
        AssociateType.CompanyAccountOwner
      ) {
        // todo: update to new attribute
        return this.$store.state.associate.action === AssociateAction.CCExpiring
      
      }
      return false
    },
    expiredCard: function (): boolean {
      if (
        this.$store.state.associate?.associate_type?.name ===
        AssociateType.CompanyAccountOwner
      ) {
        // todo: update to new attribute
        return this.$store.state.associate.action === AssociateAction.CCExpired
      
      }
      return false
    },
  },
  methods: {
    navigate() {
      this.$router.push("/accountplan");
    },
  },
  watch: {
    // expiringCard() {
    //   console.log(this.expiringCard)
    // }
  }
});
