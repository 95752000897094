import { AssociateRoles, GssiRoles, IAssociate } from "@/api";


export const ASSOCIATE_ROLES = [
  {
    label: 'Account',
    value: AssociateRoles.ManageAccount,
    icon: 'mdi-account-cog-outline'
  },
  {
    label: 'Groups',
    value: AssociateRoles.ManageGroups,
    icon: 'mdi-account-group-outline'
  },
  {
    label: 'Projects',
    value: AssociateRoles.ManageProjects,
    icon: 'mdi-clipboard-text-outline'
  },
  {
    label: 'Reports',
    value: AssociateRoles.ManageReports,
    icon: 'mdi-file-chart-outline'
  },
  {
    label: 'Equipment',
    value: AssociateRoles.ManageEquipment,
    icon: 'mdi-cogs'
  },
]

export const GSSI_USER_ROLES = [
  {
    label: 'All Users',
    value: GssiRoles.ManageUsers,
    icon: 'mdi-account-group-outline'
  },
  {
    label: 'Shared Projects',
    value: GssiRoles.SharedProjects,
    icon: 'mdi-folder-open'
  },
  {
    label: 'All Equipment',
    value: GssiRoles.ManageEquipment,
    icon: 'mdi-cogs'
  },
]

export const Labels = {
  Hour: Array.from({length: 24}, (_, i) => `${i}:00`),
  DayName: [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ],
  DayNumber: Array.from({length: 31}, (_, i) => i + 1),
  Month: [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ]
} as any

export const AvatarColors = ['#7E3693', '#99BB5B', '#4476E3', '#355396', '#963F2D', '#1E9667', '#AB37A2', '#948B3E', '#2F8494', '#BA5B49']
export const getAvatarColor = () => {
  return AvatarColors[Math.floor(Math.random()*AvatarColors.length)]
}
