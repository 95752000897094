import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const light = {
  primary: '#454b60',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
}

const dark = {
  primary: '#ec734b', //red
  secondary: '#2A3138', //light gray
  accent: '#41a5f2',//blue
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
}
export default new Vuetify({
  theme: {
    dark: true,
    themes: { dark, light },
  }
});
