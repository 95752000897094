import axios from 'axios'
import { IBaseResponse, baseUrl, transformResponse } from '../common'
import { IMakePaymentRequest, IMakePaymentResponse, IPaymentMethodGetResponse, IPaymentMethodAddRequest, IPaymentMethodAddResponse, IPaymentOrdersResponse, ICheckoutCostResponse, IOutstandingBalanceResponse, IPayAllInvoiceResponse } from './models'

// response is not transformed due to inconsistency 

const addPaymentMethodUrl = () => baseUrl + '/payment_methods'
const addPaymentMethod = async (request: IPaymentMethodAddRequest, companyId?: number): Promise<IPaymentMethodAddResponse> => {
  const params = {} as any
  if (companyId) {
    params.company_id = companyId
  }
  return transformResponse(await axios.post(
    addPaymentMethodUrl(),
    request,
    { params }
  ))
}

const getPaymentMethodsUrl = () => baseUrl + '/stripe_payment_methods'
const getPaymentMethods = async (companyId: number): Promise<IPaymentMethodGetResponse> => {
  return transformResponse(await axios.get(
    getPaymentMethodsUrl(),
    {
      params: {
        company_id: companyId
      }
    }
  ))
}

const checkoutCostsUrl = () => baseUrl + '/checkout_cost'
const getCheckoutCost = async (params: { total_users?: number, storage: number, company_id?: number }): Promise<ICheckoutCostResponse> => {
  return transformResponse(await axios.get(
    checkoutCostsUrl(),
    { params }
  ))
}

const makePaymentUrl = () => baseUrl + '/checkout_payment_intent'
const makePayment = async (request: IMakePaymentRequest, company_id?: number): Promise<IMakePaymentResponse> => {
  return transformResponse(await axios.post(
    makePaymentUrl(),
    request,
    {
      params: {
        company_id
      }
    }
  ))
}

const paymentOrdersUrl = () => baseUrl + '/payment_orders'
const getPaymentOrders = async (companyId: number): Promise<IPaymentOrdersResponse> => {
  const params = {
    list_info: {
      search_fields: {
        company_id: companyId
      }
    }
  } as any
  return transformResponse(await axios.get(
    paymentOrdersUrl(), //+ `/company_id=${companyId}`,
    { params }
  ))
}
const getPaymentOrder = async (orderId: number): Promise<IPaymentOrdersResponse> => {
  return transformResponse(await axios.get(
    paymentOrdersUrl() + "/" + orderId, 
  ))
}

const outstandingDuesUrl = () => baseUrl + '/outstanding_dues'
const getOutstandingDues = async (companyId?: number): Promise<IOutstandingBalanceResponse> => {
  const params = {} as any
  if (companyId) {
    params.company_id = companyId
  }
  return transformResponse(await axios.get(
    outstandingDuesUrl(),
    { params }
  ))
}

const payAllInvoiceDuesURL = () => baseUrl + '/pay_all_invoice_dues'
const payAllInvoiceDues = async (companyId?: number): Promise<IPayAllInvoiceResponse> => {
  const params = {} as any
  if (companyId) {
    params.company_id = companyId
  }
  return transformResponse(await axios.post(
    payAllInvoiceDuesURL(),
    undefined,
    { params }
  ))
}


export const checkout = {
  addPaymentMethod,
  getPaymentMethods,
  getCheckoutCost,
  makePayment,
  getPaymentOrders,
  getPaymentOrder,
  getOutstandingDues,
  payAllInvoiceDues
}
export * from './models'