import { AssociateAction, AssociateType, CompanyStatus, IAssociate } from '@/api';
import store from '../store'
import router from '@/router';

export const navs = {
  computed: {
    associate: function (): IAssociate {
      return (this as any).$store.state.associate
    }
  },
  methods: {
    goToInitial(): void {
      // gssi admin
      if (store.getters.isGssiAdmin) {
        router.push("/gssiadmin/accounts");
        return
      }

      // handle account owner actions 
      if ((this as any).associate.associate_type?.name === AssociateType.CompanyAccountOwner) {
        if (!(this as any).associate?.user_agreement) {
          router.push("/signup/accounttransferterms");
          return
        }
        if ((this as any).associate?.action === AssociateAction.CCDeclined) {
          router.push("/login/billing");
          return
        }
        if ((this as any).associate?.action === AssociateAction.CCExpiring) {
          router.push("/accountmanagement");
          return
        }
      }

      // suspended account
      if (store.state.company?.status === CompanyStatus.SUSPENDED) {
        router.push("/login/suspended");
        return
      }

      router.push("/dashboard");
    }
  }
};
